import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from '../../../../../../Base/Slider/Slider';
import { Confirmation } from '../../../../../../Base/Modal';
import Select from 'react-select';
import { FormGroup, Label, Input, Col } from 'reactstrap';
import Action from './Action';
import { CreateButton, CancelButton, DeleteButton } from '../../../../../../Base/Buttons';
import InputNode from './InputNode';
import ConditionalGroupBox from './ConditionalGroupBox';
import styled from 'styled-components';

const StyledTextArea = styled(Input)`
  min-height: 250px !important;
`;

const StyledNodeContent = styled.div`
  background: white;
  padding: 20;
  border-radius: 10;
  padding: 20;
  display: flex;
  flex-direction: column;
`;

const WaitTypes = [
  { label: 'Minutes', value: 'min' },
  { label: 'Hours', value: 'hour' },
  { label: 'Days', value: 'day' },
  { label: 'Weeks', value: 'week' },
];

function NodeContent({ selectedNode, updateNode, value }) {
  if (selectedNode.type === 'annotationNode') {
    return (
      <FormGroup>
        <Label>Notes</Label>
        <StyledTextArea
          name="text"
          type="textarea"
          value={value.label}
          onChange={(e) => {
            updateNode({ label: e.target.value });
          }}
        />
      </FormGroup>
    );
  }

  if (selectedNode.type === 'waitNode') {
    return (
      <FormGroup>
        <Label>Choose Period to Wait</Label>
        <FormGroup row>
          <Label for="number">Value</Label>
          <Col>
            <Input
              id="number"
              type="number"
              value={value?.number}
              onChange={(e) => updateNode({ ...value, number: e.target.value })}
            />
          </Col>
        </FormGroup>
        <Select
          className="react-select"
          placeholder="Select Range"
          options={WaitTypes}
          value={WaitTypes.find((a) => a.value === value?.range)}
          onChange={(val) => {
            updateNode({ ...value, range: val.value });
          }}
        />
      </FormGroup>
    );
  }

  return (
    <>
      <FormGroup>
        <Label>Label</Label>
        <Input
          id="Name"
          onChange={(e) => {
            updateNode({ label: e.target.value });
          }}
          type="text"
          value={value.label}
        />
      </FormGroup>
      {selectedNode.type === 'startNode' && (
        <InputNode selectedNode={value} onChange={(updateValue) => updateNode(updateValue)} />
      )}
      {selectedNode.type === 'decisionNode' && (
        <ConditionalGroupBox selectedNode={value} onChange={(updateValue) => updateNode(updateValue)} />
      )}
      {selectedNode.type === 'actionNode' && (
        <Action option={value} setOption={(updateValue) => updateNode(updateValue)} />
      )}
    </>
  );
}

function EditNodeSlider({ isSliderOpen, closeSlider, selectedNode, onDelete, onSave }) {
  const [showConfirm, setShowConfirm] = useState(false);
  const [value, setValue] = useState(selectedNode?.data);

  useEffect(() => {
    setValue(selectedNode?.data);
  }, [isSliderOpen, selectedNode?.data]);

  return (
    <Fragment>
      <Slider
        title="Edit Node"
        isOpen={isSliderOpen}
        toggleOpen={(isBgClick) => {
          if (isBgClick) {
            setShowConfirm(true);
          } else {
            closeSlider(value);
          }
        }}
        focusForm={false}
        width="100%"
        pauseNotificationsOnOpen
      >
        <StyledNodeContent>
          <NodeContent
            selectedNode={{ ...selectedNode }}
            value={value}
            updateNode={(a) => {
              setValue({
                ...value,
                ...a,
              });
            }}
          />
          <div className="d-flex ms-auto gap-2">
            <DeleteButton label="Delete Node" action={() => onDelete()} className=" mt-2" floatRight={false} />
            <CreateButton
              className="mt-2"
              label={'Save'}
              floatRight={false}
              action={(e) => {
                e.preventDefault();
                onSave(value);
              }}
            />
            <CancelButton
              className="mt-2 ms-2"
              floatRight={false}
              action={() => {
                closeSlider(value);
              }}
            />
          </div>
        </StyledNodeContent>
      </Slider>
      <Confirmation
        content="Your data will be lost if you close the slider"
        show={showConfirm}
        cancelCallback={() => setShowConfirm(false)}
        confirmCallback={() => {
          setShowConfirm(false);
          closeSlider(value);
        }}
      />
    </Fragment>
  );
}

EditNodeSlider.propTypes = {
  isSliderOpen: PropTypes.bool,
  closeSlider: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  title: PropTypes.string,
};

EditNodeSlider.defaultProps = {
  isSliderOpen: false,
  closeSlider: () => {},
  children: () => {},
  title: null,
};

export default EditNodeSlider;
