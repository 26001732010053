export const payrollStates = [
  { label: 'Failed to submit', value: 'received' },
  { label: 'Submitted Successfully', value: 'requested' },
];

export const defaultStates = [
  { label: 'Received', value: 'received' },
  { label: 'Requested', value: 'requested' },
];

export const referenceStates = [
  { label: 'Received', value: 'received' },
  { label: 'Part Recieved', value: 'partReceived' },
  { label: 'Requested', value: 'requested' },
];

export const rtwStates = [
  { label: 'Received', value: 'received' },
  { label: 'Requested', value: 'requested' },
];
export const rtwOutcomes = [
  { value: '', label: 'Any' },
  { value: 'REJECTED', label: 'Rejected' },
  { value: 'TIME_LIMITED', label: 'Time Limited' },
  { value: 'CONTINUOUS', label: 'Continuous' },
];

export const personalityTestState = [
  { label: 'Received', value: 'received' },
  { label: 'Requested', value: 'requested' },
];

export const personalityTestScoreValues = [
  { value: '', label: 'Any' },
  { value: 'lessThan', label: 'Less Than' },
  { value: 'equalTo', label: 'Equal to' },
  { value: 'greaterThan', label: 'Greater Than' },
];

export const personalityTestScoreTypes = [
  { value: '', label: 'Integrity Score' },
  { value: 'other', label: 'Other' },
];

export const eventStates = [
  { label: 'Invited', value: 'received' },
  { label: 'Accepted', value: 'requested' },
  { label: 'Declined', value: 'declined' },
  { label: 'Attended', value: 'attended' },
  { label: 'No Show', value: 'noShow' },
];

export const newCandidateFilterTypes = [
  { label: 'All', value: 'all' },
  { label: 'UTM Source', value: 'utmSource' },
  { label: 'UTM Campaign', value: 'utmCampaign' },
  { label: 'UTM Medium', value: 'utmMedium' },
];

export default {
  payrollStates,
  defaultStates,
  referenceStates,
  rtwStates,
  rtwOutcomes,
  personalityTestState,
  personalityTestScoreValues,
  personalityTestScoreTypes,
  eventStates,
  newCandidateFilterTypes,
};
