import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CbCell = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

function CheckboxCell({ id, className, checked, onChange }) {
  return (
    <CbCell className={className || ''}>
      <input id={id} checked={checked} type="checkbox" onChange={(e) => onChange(e.target.checked)} />
    </CbCell>
  );
}

CheckboxCell.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

CheckboxCell.defaultProps = {
  className: null,
  checked: false,
  onChange: () => {},
};

function MatrixCheckboxes({ id, className, value, onChange, crud }) {
  const isAllow = crud.includes('allow');
  const isRead = crud.includes('read');
  const isPreview = crud.includes('preview');
  const isView = crud.includes('view');
  const isWrite = crud.includes('write');
  const isCreate = crud.includes('create');
  const isUpdate = crud.includes('update');
  const isDelete = crud.includes('delete');
  const isAdmin = crud.includes('admin');
  const isReadOnly = crud.includes('readonly');

  return (
    <Fragment>
      {isAllow || isView || isRead || isPreview || isReadOnly ? (
        <CheckboxCell
          id={`${id}-view`}
          className={className}
          checked={value.some((str) => /^(allow|view|read|preview|readonly)$/.test(str))}
          onChange={(checked) => {
            let subType;

            if (isAllow) subType = 'allow';
            if (isRead) subType = 'read';
            if (isPreview) subType = 'preview';
            if (isReadOnly) subType = 'readonly';

            onChange('view', checked, subType);
          }}
        />
      ) : (
        <CbCell className={className || ''} />
      )}
      {isWrite || isCreate ? (
        <CheckboxCell
          id={`${id}-create`}
          className={className}
          checked={value.some((str) => /^(write|create)$/.test(str))}
          onChange={(checked) => {
            let subType;

            if (isWrite) subType = 'write';

            onChange('create', checked, subType);
          }}
        />
      ) : (
        <CbCell className={className || ''} />
      )}
      {isUpdate || isAdmin ? (
        <CheckboxCell
          id={`${id}-update`}
          className={className}
          checked={value.some((str) => /^(update|admin)$/.test(str))}
          onChange={(checked) => {
            let subType;

            if (isAdmin) subType = 'admin';

            onChange('update', checked, subType);
          }}
        />
      ) : (
        <CbCell className={className || ''} />
      )}
      {isDelete ? (
        <CheckboxCell
          id={`${id}-delete`}
          className={className}
          checked={value.includes('delete')}
          onChange={(checked) => onChange('delete', checked)}
        />
      ) : (
        <CbCell className={className || ''} />
      )}
    </Fragment>
  );
}

MatrixCheckboxes.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  crud: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: () => {},
};

MatrixCheckboxes.defaultProps = {
  className: null,
  crud: [],
  value: [],
  onChange: () => {},
};

export default MatrixCheckboxes;
