import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PageColumn } from '../Dashboard';
import { CreateButton, EditButton } from '../../../Base/Buttons';
import WorkflowWrapper from './DragAndDrop/WorkflowWrapper';
import ErrorConditionsSlider from './DragAndDrop/Sliders/ErrorConditionsSlider/ErrorConditionsSlider';
import ExitConditionsSlider from './DragAndDrop/Sliders/ExitConditionsSlider/ExitConditionsSlider';
import Validation, { validate } from './DragAndDrop/Validation';
import { Input } from 'reactstrap';
import styled from 'styled-components';

const StyledBackButtonContainer = styled.div`
  font-size: 14px;
  color: #1a2e56;
  font-weight: 600;
  margin-bottom: 1rem;
`;

const StyledBackButton = styled.div`
  width: 20px;
  cursor: pointer;
  color: #1a2e56;
  margin-bottom: auto;
  margin-right: 6px;
  display: flex;
`;

function WorkflowEditPanel({ goBackToEdit, workflowObj }) {
  const [isErrorConditionsOpen, setIsErrorConditionsOpen] = useState(false);
  const [isExitConditionsOpen, setIsExitConditionsOpen] = useState(false);
  const [errors, setErrors] = useState([]);
  const [currentWorkflow, setCurrentWorkflow] = useState(null);

  useEffect(() => {
    const isValid = validate(workflowObj);
    setCurrentWorkflow(workflowObj);
    setErrors(isValid);
  }, [workflowObj]);

  if (!currentWorkflow) return null;

  return (
    <PageColumn className="pt-3 d-flex flex-column">
      <StyledBackButtonContainer className="d-flex">
        <StyledBackButton
          onClick={() => {
            goBackToEdit();
          }}
        >
          <svg
            class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-dhaba5"
            focusable="false"
            aria-hidden="true"
            viewBox="0 0 24 24"
            data-testid="ArrowBackIcon"
            fill="#1A2E56"
          >
            <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20z"></path>
          </svg>
        </StyledBackButton>
        <span>Back</span>
      </StyledBackButtonContainer>
      <div className="d-flex pb-3 justify-content-between w-100">
        <div className="d-flex gap-2">
          <h5 style={{ marginTop: '7px', marginRight: '10px' }}>Name:</h5>
          <Input
            id="name"
            onChange={(e) => setCurrentWorkflow({ ...currentWorkflow, name: e.target.value })}
            type="text"
            value={currentWorkflow.name || ''}
          />
        </div>
        <div className="d-flex gap-2">
          <EditButton
            className="mt-2"
            floatRight={false}
            label={'Exit Conditions'}
            action={() => {
              setIsExitConditionsOpen(true);
            }}
          />
          <CreateButton
            className="mt-2"
            floatRight={false}
            label={'Error Conditions'}
            action={() => setIsErrorConditionsOpen(true)}
          />
          <Validation errors={errors} />
          <EditButton
            className="mt-2"
            floatRight={false}
            label={'Activate'}
            disabled={errors.length > 0}
            action={() => console.log('Activate')}
          />
          <CreateButton className="mt-2" floatRight={false} label={'Save'} action={() => console.log('Save')} />
        </div>
      </div>
      <WorkflowWrapper
        data={currentWorkflow}
        initialEdges={currentWorkflow.edges}
        initialNodes={currentWorkflow.nodes}
        updateNodes={(nodes, edges) => {
          setCurrentWorkflow({ ...currentWorkflow, nodes, edges });
        }}
      />
      <ExitConditionsSlider
        isSliderOpen={isExitConditionsOpen}
        closeSlider={(e, updatedEdgesedges) => {
          setIsExitConditionsOpen(false);
        }}
        onSave={(value) => {
          setIsExitConditionsOpen(false);
        }}
        onDelete={() => {
          setIsExitConditionsOpen(false);
        }}
      />
      <ErrorConditionsSlider
        isSliderOpen={isErrorConditionsOpen}
        closeSlider={(e, updatedEdgesedges) => {
          setIsErrorConditionsOpen(false);
        }}
        onSave={(value) => {
          setIsErrorConditionsOpen(false);
        }}
        onDelete={() => {
          setIsErrorConditionsOpen(false);
        }}
      />
    </PageColumn>
  );
}

WorkflowEditPanel.propTypes = {
  goBackToEdit: PropTypes.func,
  workflowObj: PropTypes.shape(),
};

WorkflowEditPanel.defaultProps = {
  goBackToEdit: () => {},
  workflowObj: {},
};

export default WorkflowEditPanel;
