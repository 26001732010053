/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Col, Form, Row } from 'reactstrap';
import validation, { mapErrors } from '../../../../js/utils/validation';
import { CancelButton, CreateButton } from '../../../Base/Buttons';
import { useFormKeypress, useMounted } from '../../../Base/hooks';
import IntegrationInput from './IntegrationInput';
import { Loader } from '../../../Base/Loading';
import { WorkflowContext } from '../../../Base/contexts/WorflowContext';
import { requestStatuses } from '../../../../js/constants/requestStatuses';
import Tippy from '@tippyjs/react';

function isCreateRestricted(res, candidateProfileSummary, componentName) {
  const buttonRestrictions = res?.find(({ component }) => component === componentName) || {};

  if (!buttonRestrictions?.restrictions?.length) return false;

  const isRestricted = buttonRestrictions.restrictions.some(({ component, enableState }) => {
    if (component === 'RTW_REQUEST') {
      return candidateProfileSummary.rightToWork.status
        ? enableState.some((state) => state === candidateProfileSummary.rightToWork.status)
        : true;
    }

    if (component === 'EDOC') {
      return candidateProfileSummary.edoc.length > 0
        ? candidateProfileSummary.edoc.every((edoc) => {
            return enableState.some((state) => state === edoc.status);
          })
        : false;
    }
  });

  return isRestricted ? buttonRestrictions.restrictionMsg : false;
}

function IntegrationForm({
  title,
  applicantId,
  integrationName,
  formConfig,
  valueSetter,
  getSubmission,
  componentName,
  getValidationConfig,
  uploadFile,
  candidateProfileSummary,
  customOnChange,
  formSubmit,
}) {
  const { data } = useContext(WorkflowContext);
  const initFormData = formConfig.reduce(
    (acc, { id, type, defaultValue }) => ({ ...acc, [id]: type === 'number' ? defaultValue : '' }),
    {},
  );

  const isMounted = useMounted();
  const formRef = useFormKeypress();
  const [formData, setFormData] = useState(initFormData);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [formKey, setFormKey] = useState('');
  const [errors, setErrors] = useState({});
  const [failReason, setFailReason] = useState();
  const [submissionStatus, setSubmissionStatus] = useState('INCOMPLETE');

  useEffect(() => {
    async function checkData() {
      const resp = await getSubmission(applicantId);

      if (resp === 'NOT_FOUND_ERROR') {
        setIsLoading(false);
        return;
      }

      const { status, data, message } = resp;

      if (status !== 'SUCCESS') {
        if (status === 'FAILED') {
          setSubmissionStatus('FAILED');
          setFailReason(message);
          setFormData(data);
        } else {
          toast.error(`Error fetching ${integrationName} information. Please try again later or contact support`);
          setFailReason(
            `There was an error fetching the applicant's ${integrationName} information. Please try again later or contact support`,
          );
        }
      } else {
        setFormData(data);
        setSubmissionStatus('COMPLETE');
      }
      setIsLoading(false);
    }
    if (isMounted()) {
      checkData();
    }
  }, [isMounted, applicantId]);

  function handleChange(id, value) {
    const updatedData = { ...formData, [id]: value };

    setFormData(updatedData);
  }

  async function handleSave() {
    setIsSaving(true);

    setFailReason(null);

    const errObj = validation(getValidationConfig(formData), formData);
    const { messages, hasErrors } = mapErrors(errObj);
    setErrors(messages);

    if (!hasErrors) {
      const resp = await formSubmit(applicantId, formData);

      const { status, message, data } = resp;

      if (status !== 'SUCCESS') {
        if (status === 'FAILED') {
          setSubmissionStatus('FAILED');
          setFailReason(message);
          setFormData(data);
        } else if (resp === requestStatuses.INVALID_CREDENTIALS) {
          setFailReason('Error submitting form. Invalid Credentials. Please check settings and try again.');
        } else {
          setFailReason('Error submitting form, please try again or contact support');
        }
        toast.error('Error submitting form, please try again or contact support');
      } else {
        setFormData(data);
        toast.success('Form submitted successfully');
        setSubmissionStatus('COMPLETE');
      }
    }

    setIsSaving(false);
  }

  function reset() {
    setFormData({ ...initFormData });
    setErrors({});
    setFormKey(Math.random().toString(10));
  }

  const isComplete = submissionStatus === 'COMPLETE';
  const isFailed = submissionStatus === 'FAILED';
  const isCreateButtonRestricted =
    isSaving || isLoading || isCreateRestricted(data, candidateProfileSummary, componentName);

  return (
    <>
      <hr />
      <Row>
        {isLoading ? (
          <Loader />
        ) : (
          <Col>
            <div className="d-flex align-items-center mb-2">
              <h4 className="mb-0 me-auto">{`${title} - ${
                isComplete ? 'Completed' : isFailed ? 'Failed' : 'Incomplete'
              }`}</h4>
            </div>
            {failReason && (
              <div className="alert alert-danger">
                <p>{failReason}</p>
              </div>
            )}
            <Form key={formKey} innerRef={formRef}>
              {formConfig.map((input) => {
                const { id, type, label, options, required, inputProps, dataMapper, url: initialUrl } = input;
                const updatedValues = valueSetter(
                  initialUrl,
                  formData[id] || '',
                  false,
                  isComplete,
                  type,
                  formData,
                  id,
                );

                if (updatedValues.isHidden) {
                  return null;
                }

                return (
                  <IntegrationInput
                    key={id}
                    dataMapper={dataMapper}
                    error={errors[id]}
                    id={id}
                    inputProps={inputProps}
                    isClearable={type === 'select' && options?.length > 1}
                    isFile={id === 'visaFileId'}
                    isDisabled={updatedValues?.isDisabled}
                    label={label}
                    onChange={(val) => {
                      customOnChange ? customOnChange(id, val, formData, setFormData) : handleChange(id, val);
                    }}
                    options={options}
                    readOnly={isComplete}
                    required={required}
                    uploader={uploadFile}
                    type={type}
                    url={updatedValues?.url}
                    value={updatedValues?.value}
                  />
                );
              })}
              {!isComplete && (
                <div className="float-end d-flex">
                  <CancelButton
                    className="mt-2"
                    label="Clear"
                    isLoading={isSaving}
                    disabled={isSaving}
                    action={() => reset()}
                  />
                  <Tippy
                    content={isCreateButtonRestricted ? isCreateButtonRestricted || 'Cannot submit at this time' : null}
                    theme="ats"
                    disabled={!isCreateButtonRestricted}
                  >
                    <div>
                      <CreateButton
                        className="mt-2"
                        label={isSaving ? 'Submitting data...' : `Submit Candidate to ${integrationName}`}
                        isLoading={isSaving || isLoading}
                        disabled={isCreateButtonRestricted}
                        action={(e) => {
                          e.preventDefault();
                          handleSave();
                        }}
                      />
                    </div>
                  </Tippy>
                </div>
              )}
            </Form>
          </Col>
        )}
      </Row>
    </>
  );
}

IntegrationForm.propTypes = {
  applicantId: PropTypes.string,
};

IntegrationForm.defaultProps = {
  applicantId: null,
};

export default IntegrationForm;
