import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { IconSVG } from '../../../../Base/SVG';

export function validate(data) {
  if (data?.nodes && data?.nodes.length === 0) {
    return ['No start and end node'];
  }
  return [];
}

function Validation({ errors }) {
  return (
    <UncontrolledDropdown disabled={errors.length === 0}>
      <DropdownToggle className="ci-link d-flex gap-1 mt-3 mx-2" tag={errors.length === 0 ? 'div' : 'a'}>
        {errors.length === 0 ? 'Valid' : 'Invalid'}
        <IconSVG name={errors.length === 0 ? 'Tick' : 'Cross'} />
      </DropdownToggle>
      <DropdownMenu
        disabled
        end
        modifiers={[
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['top', 'right'],
            },
          },
        ]}
      >
        {errors.length === 0
          ? null
          : errors.map((error, index) => <DropdownItem onClick={() => console.log('hi')}>{error}</DropdownItem>)}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

export default Validation;
