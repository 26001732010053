import Loadable from 'react-loadable';
import CandidateView from './views/Applications/Candidates/CandidateView/CandidateView';
import { LoadingScreen } from './views/Base/Loading';
import { ClientAdminDashboard } from './views/Admin/Client/Dashboard';

const Homepage = Loadable({
  loader: () => import('./proxy/Homepage'),
  loading: LoadingScreen,
});

const CreateVacancy = Loadable({
  loader: () => import('./views/Vacancy/Create/CreateVacancy'),
  loading: LoadingScreen,
});

const VacancyList = Loadable({
  loader: () => import('./proxy/VacancyList'),
  loading: LoadingScreen,
});

const CandidateFullView = Loadable({
  loader: () => import('./proxy/CandidateFullView'),
  loading: LoadingScreen,
});

const ScheduleView = Loadable({
  loader: () => import('./views/Scheduler/ScheduleView'),
  loading: LoadingScreen,
});

const CalendarSyncCallback = Loadable({
  loader: () => import('./views/Scheduler/SyncCallBackView'),
  loading: LoadingScreen,
});

const Reporting = Loadable({
  loader: () => import('./views/Admin/Client/Reporting/Reporting'),
  loading: LoadingScreen,
});

const CVSearch = Loadable({
  loader: () => import('./views/CVSearch/CVSearchView'),
  loading: LoadingScreen,
});

const AccountList = Loadable({
  loader: () => import('./views/Admin/System/AccountsList/AccountsList'),
  loading: LoadingScreen,
});

const VideoChat = Loadable({
  loader: () => import('./views/Communication/video-conferencing/VideoChat'),
  loading: LoadingScreen,
});

const Dashboard = Loadable({
  loader: () => import('./proxy/Dashboard'),
  loading: LoadingScreen,
});

const VacancyDetails = Loadable({
  loader: () => import('./proxy/VacancyView'),
  loading: LoadingScreen,
});

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  {
    path: '/vacancy/create',
    exact: true,
    name: 'Create Vacancy',
    element: CreateVacancy,
  },
  {
    path: '/vacancy',
    name: 'View Vacancies',
    element: VacancyList,
    touch: true,
  },
  {
    path: '/candidate',
    exact: true,
    name: 'View Candidates',
    element: CandidateFullView,
    touch: true,
  },
  {
    path: '/',
    exact: true,
    name: 'Home',
    element: Homepage,
    touch: true,
  },
  {
    path: '/vacancy/view',
    exact: true,
    name: 'View Vacancy',
    element: VacancyDetails,
  },
  {
    path: '/candidate/view',
    exact: true,
    name: 'View Candidate',
    element: CandidateView,
  },
  {
    path: '/schedule',
    exact: true,
    name: 'Schedule Calendar',
    element: ScheduleView,
  },
  {
    path: '/video/interview',
    exact: true,
    name: 'Video Interview',
    element: VideoChat,
  },
  {
    path: '/cvsearch',
    exact: true,
    name: 'CV Search',
    element: CVSearch,
  },
  {
    path: '/reporting',
    exact: true,
    name: 'Reporting',
    element: Reporting,
  },
  {
    path: '/settings/personal-information',
    exact: true,
    name: 'Personal Information',
    element: ClientAdminDashboard,
  },
  {
    path: '/settings/job-boards',
    exact: true,
    name: 'Job Boards',
    element: ClientAdminDashboard,
  },
  {
    path: '/settings/company-profile',
    exact: true,
    name: 'Company Profile',
    element: ClientAdminDashboard,
  },
  {
    path: '/settings/branding',
    exact: true,
    name: 'Branding',
    element: ClientAdminDashboard,
  },
  {
    path: '/settings/vacancy',
    exact: true,
    name: 'Vacancy Settings',
    element: ClientAdminDashboard,
  },
  {
    path: '/settings/forms',
    exact: true,
    name: 'Forms Settings',
    element: ClientAdminDashboard,
  },
  {
    path: '/settings/contracts',
    exact: true,
    name: 'Contract Settings',
    element: ClientAdminDashboard,
  },
  {
    path: '/settings/funnels',
    exact: true,
    name: 'Funnels Settings',
    element: ClientAdminDashboard,
  },
  {
    path: '/settings/email-templates',
    exact: true,
    name: 'Email Template Settings',
    element: ClientAdminDashboard,
  },
  {
    path: '/settings/vacancy-templates',
    exact: true,
    name: 'Vacancy Template Settings',
    element: ClientAdminDashboard,
  },
  {
    path: '/settings/rejection-reasons',
    exact: true,
    name: 'Rejection Reasons Settings',
    element: ClientAdminDashboard,
  },
  {
    path: '/settings/schedule',
    exact: true,
    name: 'Schedule Settings',
    element: ClientAdminDashboard,
  },
  {
    path: '/settings/locations',
    exact: true,
    name: 'Location Settings',
    element: ClientAdminDashboard,
  },
  {
    path: '/settings/accounts',
    exact: true,
    name: 'Account Management',
    element: ClientAdminDashboard,
  },
  {
    path: '/settings/users',
    exact: true,
    name: 'User Management',
    element: ClientAdminDashboard,
  },
  {
    path: '/settings/tags',
    exact: true,
    name: 'Tag Settings',
    element: ClientAdminDashboard,
  },
  {
    path: '/settings/workflows',
    exact: true,
    name: 'Workflows',
    element: ClientAdminDashboard,
  },
  {
    path: '/settings/advanced-workflows',
    exact: true,
    name: 'Advanced Workflows',
    element: ClientAdminDashboard,
  },
  {
    path: '/settings/atr',
    exact: true,
    name: 'ATR',
    element: ClientAdminDashboard,
  },
  {
    path: '/settings/fourth',
    exact: true,
    name: 'Fourth Integration',
    element: ClientAdminDashboard,
  },
  {
    path: '/settings/polaris',
    exact: true,
    name: 'Polaris Integration',
    element: ClientAdminDashboard,
  },
  {
    path: '/settings/hollweg',
    exact: true,
    name: 'Hollweg Integration',
    element: ClientAdminDashboard,
  },
  {
    path: '/settings/s4',
    exact: true,
    name: 'S4 Integration',
    element: ClientAdminDashboard,
  },
  {
    path: '/settings/edocs',
    exact: true,
    name: 'eDocs',
    element: ClientAdminDashboard,
  },
  {
    path: '/account-settings',
    exact: true,
    name: 'Account Settings',
    element: AccountList,
  },
  {
    path: '/settings',
    exact: true,
    name: 'Client Admin',
    element: ClientAdminDashboard,
  },
  {
    path: '/dashboard',
    exact: true,
    name: 'Client Dashboard',
    element: Dashboard,
    touch: true,
  },
];

export default routes;
