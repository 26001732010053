import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from '../../../../../../Base/Slider/Slider';
import { Confirmation } from '../../../../../../Base/Modal';
import { CreateButton, CancelButton } from '../../../../../../Base/Buttons';

function ErrorConditionsSlider({ isSliderOpen, closeSlider, onSave }) {
  const [showConfirm, setShowConfirm] = useState(false);

  return (
    <Fragment>
      <Slider
        title="Error Conditions"
        isOpen={isSliderOpen}
        toggleOpen={(isBgClick) => {
          if (isBgClick) {
            setShowConfirm(true);
          } else {
            closeSlider();
          }
        }}
        focusForm={false}
        width="100%"
        pauseNotificationsOnOpen
      >
        <div className="d-flex flex-column">
          <p>
            The error conditions are the conditions that will be checked when the workflow is running. If the conditions
            are met, the workflow will stop and the error conditions will be triggered.
          </p>
          <div>
            <div className="d-flex mt-3">
              <input type="checkbox" id="endNode" name="endNode" value="true" checked disabled />
              <label style={{ fontWeight: 'bold', paddingLeft: '10px' }}>Send an email to the to the admin team</label>
            </div>
            <div className="d-flex mt-3">
              <input type="checkbox" id="endNode" name="endNode" value="true" />
              <label style={{ fontWeight: 'bold', paddingLeft: '10px' }}>Send a message on slack</label>
            </div>
            <div className="d-flex my-3">
              <input type="checkbox" id="endNode" name="endNode" value="true" />
              <label style={{ fontWeight: 'bold', paddingLeft: '10px' }}>Move candidate to the netherzone </label>
            </div>
          </div>
        </div>
        <CreateButton
          className="mt-2"
          label={'Save'}
          floatRight={false}
          action={(e) => {
            e.preventDefault();
            onSave();
          }}
        />
        <CancelButton
          className="mt-2 ms-2"
          floatRight={false}
          action={() => {
            closeSlider();
          }}
        />
      </Slider>
      <Confirmation
        content="Your data will be lost if you close the slider"
        show={showConfirm}
        cancelCallback={() => setShowConfirm(false)}
        confirmCallback={() => {
          setShowConfirm(false);
          closeSlider();
        }}
      />
    </Fragment>
  );
}

ErrorConditionsSlider.propTypes = {
  isSliderOpen: PropTypes.bool,
  closeSlider: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  title: PropTypes.string,
};

ErrorConditionsSlider.defaultProps = {
  isSliderOpen: false,
  closeSlider: () => {},
  children: () => {},
  title: null,
};

export default ErrorConditionsSlider;
