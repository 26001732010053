import React, { FC } from 'react';
import { getBezierPath, EdgeLabelRenderer, BaseEdge } from 'reactflow';

const CustomEdge = ({ id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition, data }) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <BaseEdge id={id} path={edgePath} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            background: '#E74C3C',
            padding: 3,
            paddingLeft: 10,
            paddingRight: 10,
            borderRadius: 6,
            fontSize: 8,
            fontWeight: 600,
            color: '#fff',
          }}
          className="nodrag nopan"
        >
          No
        </div>
      </EdgeLabelRenderer>
    </>
  );
};

export default CustomEdge;
