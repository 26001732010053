import React from 'react';
import PropTypes from 'prop-types';
import { TextButton } from '../../../Base/Buttons';
import { retryableAPICall } from '../../../../api/common-api-utils';
import { downloadFiles } from '../../../../api/FileAPI/FileAPI';
import BuildDetails from './BuildDetails';

export default function AttachmentDownload({
  data,
  titleWidth,
  touchTitleColumnSize,
  title,
  btnLabel,
}) {
  if (!data || !data.fileName) return null;

  return (
    <BuildDetails
      title={title}
      titleColumnSize={titleWidth}
      touchTitleColumnSize={touchTitleColumnSize || titleWidth}
    >
      <TextButton
        floatRight={false}
        label={btnLabel || 'Download'}
        className="btn-outline"
        size="sm"
        action={async () => {
          await retryableAPICall(() => downloadFiles([data.id]));
        }}
      />
    </BuildDetails>
  );
}

AttachmentDownload.propTypes = {
  title: PropTypes.string,
  btnLabel: PropTypes.string,
  data: PropTypes.shape({
    content: PropTypes.shape({
      fileName: PropTypes.string,
      contentType: PropTypes.string,
    }),
    name: PropTypes.string,
    type: PropTypes.string,
  }),
  // isTouch: PropTypes.bool,
  titleWidth: PropTypes.number,
  touchTitleColumnSize: PropTypes.number,
};

AttachmentDownload.defaultProps = {
  title: null,
  btnLabel: null,
  data: {},
  // isTouch: false,
  titleWidth: 3,
  touchTitleColumnSize: 4,
};
