import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { FormGroup, Label, Input } from 'reactstrap';
import FunnelSelectInputs from '../../../../../../Base/Forms/Custom/FunnelSelect/FunnelSelectInputs';
import EventTypeSelect from './EventTypeSelect';
import {
  payrollStates,
  defaultStates,
  referenceStates,
  rtwStates,
  rtwOutcomes,
  personalityTestState,
  personalityTestScoreValues,
  personalityTestScoreTypes,
  eventStates,
  newCandidateFilterTypes,
} from '../../../EventOptions';

function InputNode({ selectedNode, onChange }) {
  if (selectedNode?.actionType === 'FUNNEL_STATUS_CHANGE') {
    return (
      <>
        <FormGroup>
          <FunnelSelectInputs
            funnelSelectProps={{
              id: `actionFunnelId`,
              label: 'Funnel',
              required: true,
              value: selectedNode?.funnelId || '',
            }}
            onChange={(val) => onChange({ funnelId: val.funnelId, stageId: val.stageId })}
            stageSelectProps={{
              id: `actionStageId`,
              label: 'Stage',
              required: true,
              value: selectedNode?.stageId || '',
            }}
          />
        </FormGroup>
      </>
    );
  }

  if (selectedNode?.actionType === 'PAYROLL_SUBMISSION_STATUS_CHANGE') {
    return (
      <FormGroup>
        <Label>Choose Status</Label>
        <Select
          className="react-select"
          placeholder="Select Status"
          options={payrollStates}
          value={payrollStates.find((a) => a.value === selectedNode?.condition)}
          onChange={(val) => onChange({ condition: val.value })}
        />
      </FormGroup>
    );
  }

  if (
    selectedNode?.actionType === 'ONBOARDING_STATUS_CHANGE' ||
    selectedNode?.actionType === 'EDOC_STATUS_CHANGE' ||
    selectedNode?.actionType === 'DBS_STATUS_CHANGE' ||
    selectedNode?.actionType === 'REFEREE_STATUS_CHANGE' ||
    selectedNode?.actionType === 'FLEXIFORM_STATUS_CHANGE'
  ) {
    return (
      <FormGroup>
        <Label>Choose Status</Label>
        <Select
          className="react-select"
          placeholder="Select Status"
          options={defaultStates}
          value={defaultStates.find((a) => a.value === selectedNode?.condition)}
          onChange={(val) => onChange({ condition: val.value })}
        />
      </FormGroup>
    );
  }

  if (selectedNode?.actionType === 'REFERENCE_STATUS_CHANGE') {
    return (
      <FormGroup>
        <Label>Choose Status</Label>
        <Select
          className="react-select"
          placeholder="Select Status"
          options={referenceStates}
          value={referenceStates.find((a) => a.value === selectedNode?.condition)}
          onChange={(val) => onChange({ condition: val.value })}
        />
      </FormGroup>
    );
  }

  if (selectedNode?.actionType === 'RTW_STATUS_CHANGE') {
    return (
      <div>
        <FormGroup>
          <Label>Choose Status</Label>
          <Select
            className="react-select"
            placeholder="Select Status"
            options={rtwStates}
            value={rtwStates.find((a) => a.value === selectedNode?.condition)}
            onChange={(val) => onChange({ condition: val.value })}
          />
        </FormGroup>
        {selectedNode?.condition?.value === 'received' && (
          <FormGroup>
            <Label>Outcome</Label>
            <Select
              className="react-select"
              placeholder="Select Outcome"
              options={rtwOutcomes}
              value={rtwOutcomes.find((a) => a.value === selectedNode?.outcome)}
              onChange={(val) => onChange({ outcome: val })}
            />
          </FormGroup>
        )}
      </div>
    );
  }

  if (selectedNode?.actionType === 'PT_STATUS_CHANGE') {
    return (
      <div>
        <FormGroup>
          <Label>Choose Status</Label>
          <Select
            className="react-select"
            placeholder="Select Status"
            options={personalityTestState}
            value={personalityTestState.find((a) => a.value === selectedNode?.condition)}
            onChange={(val) => onChange({ condition: val.value })}
          />
        </FormGroup>
        {selectedNode?.condition?.value === 'received' && (
          <FormGroup>
            <Label>When Score is</Label>
            <Select
              className="react-select"
              placeholder="Select Outcome"
              options={personalityTestScoreValues}
              value={personalityTestScoreValues.find((a) => a.value === selectedNode?.outcome)}
              onChange={(val) => onChange({ outcome: val })}
            />
          </FormGroup>
        )}

        {selectedNode?.condition?.value === 'received' && (
          <FormGroup>
            <Label>Score Value</Label>
            <Input
              onChange={(e) => {
                onChange({ scoreValue: e.target.value });
              }}
              type="text"
              value={selectedNode?.scoreValue}
            />
          </FormGroup>
        )}
        {selectedNode?.condition?.value === 'received' && (
          <FormGroup>
            <Label>Score Type</Label>
            <Select
              className="react-select"
              placeholder="Select Score type"
              options={personalityTestScoreTypes}
              value={personalityTestScoreTypes.find((a) => a.value === selectedNode?.scoreType)}
              onChange={(val) => onChange({ scoreType: val })}
            />
          </FormGroup>
        )}
      </div>
    );
  }
  if (selectedNode?.actionType === 'EVENT_STATUS_CHANGE') {
    return (
      <>
        <FormGroup>
          <Label>Choose Status</Label>
          <Select
            className="react-select"
            placeholder="Select Funnel Stage"
            options={eventStates}
            value={eventStates.find((a) => a.value === selectedNode?.condition)}
            onChange={(val) => onChange({ condition: val.value })}
          />
        </FormGroup>
        <EventTypeSelect
          value={selectedNode?.eventType}
          error={null}
          onChange={(eventType) => onChange({ eventType })}
        />
      </>
    );
  }

  if (selectedNode?.actionType === 'NEW_CANDIDATE') {
    return (
      <>
        <FormGroup>
          <Label>Candidate Filter Type</Label>
          <Select
            className="react-select"
            placeholder="Select Funnel Stage"
            options={newCandidateFilterTypes}
            value={newCandidateFilterTypes.find((a) => a.value === selectedNode?.condition)}
            onChange={(val) => onChange({ condition: val })}
          />
        </FormGroup>
        {(selectedNode?.condition?.value === 'utmSource' ||
          selectedNode?.condition?.value === 'utmCampaign' ||
          selectedNode?.condition?.value === 'utmMedium') && (
          <FormGroup>
            <Label>Value</Label>
            <Input
              onChange={(e) => onChange({ scoreValue: e.target.value })}
              type="text"
              placeholder="Set value to filter this workflow on"
              value={selectedNode?.scoreValue}
            />
          </FormGroup>
        )}
        {selectedNode?.condition?.value === 'all' && (
          <FunnelSelectInputs
            funnelSelectProps={{
              id: `actionFunnelId`,
              label: 'Funnel',
              required: true,
              value: selectedNode?.funnelId || '',
            }}
            onChange={(val) => onChange({ funnelId: val.funnelId, stageId: val.stageId })}
            stageSelectProps={{
              id: `actionStageId`,
              label: 'Stage',
              required: true,
              value: selectedNode?.stageId || '',
              hidden: true,
            }}
          />
        )}
      </>
    );
  }
}

InputNode.propTypes = {
  selectedNode: PropTypes.shape(),
  onChange: PropTypes.func,
};

InputNode.defaultProps = {
  selectedNode: {},
  onChange: () => {},
};

export default InputNode;
