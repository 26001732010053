import File from '../Icons/File';
import Event from '../Icons/Event';
import User from '../Icons/User';
import Money from '../Icons/Money';
import Clock from '../Icons/Clock';
import Exit from '../Icons/Exit';
import Tree from '../Icons/Tree';
import Group from '../Icons/Group';
import BuildingHouse from '../Icons/BuildingHouse';
import CheckCircle from '../Icons/CheckCircle';
import Like from '../Icons/Like';
import Funnel from '../Icons/Funnel';
import Bell from '../Icons/Bell';
import Send from '../Icons/Send';
import Email from '../Icons/Email';
import Pencil from '../Icons/Pencil';

const icon = {
  REQUEST_ONBOARDING: File,
  REQUEST_REFEREES: Group,
  SEND_TO_UBEYA: Send,
  SEND_TO_FLOW: Send,
  SPECIFIC_SEND_FLEXIFORM_REQUEST: File,
  SEND_TO_SMART: Send,
  REQUEST_RIGHT_TO_WORK_DOCS: BuildingHouse,
  SPECIFIC_EDOCUMENTS_REQUEST: File,
  REQUEST_PERSONALITY_TEST: Like,
  SEND_CANDIDATE_CONTACT_EMAIL: Email,
  UPDATE_FUNNEL_STAGE: Funnel,
  REQUEST_REFERENCES: Group,
  REQUEST_DBS: File,
  ADD_REMOVE_TAGS: File,
  REQUEST_SPECIFIC_EDOC: File,
  SEND_USER_EMAIL: Email,
  SEND_REMINDERS: Bell,
  SEND_SMS: Send,
  ADD_TO_TALENT_POOL: Send,
  EDOC_STATUS_CHANGE: File,
  EVENT_STATUS_CHANGE: Event,
  FLEXIFORM_STATUS_CHANGE: File,
  FUNNEL_STATUS_CHANGE: Funnel,
  CREATE_CANDIDATE_EVENT: User,
  NEW_CANDIDATE: User,
  ONBOARDING_STATUS_CHANGE: BuildingHouse,
  PAYROLL_SUBMISSION_STATUS_CHANGE: Money,
  PT_STATUS_CHANGE: Like,
  REFEREE_STATUS_CHANGE: Group,
  REFERENCE_STATUS_CHANGE: Group,
  RTW_STATUS_CHANGE: CheckCircle,
  EXIT: Exit,
  WAIT: Clock,
  DECISION: Tree,
  ANNOTATION: Pencil,
};

const iconGetter = (iconName) => {
  return icon[iconName];
};

export default iconGetter;
