import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Handle, NodeToolbar, Position } from 'reactflow';

const AnalyticsNode = ({ data }) => {

  const [fieldList, setFieldList] = useState([])

  const formatDate = (date) => {
    return moment(date).format('DD/MM/YYYY HH:mm:ss');
  };

  useEffect(() => {
    const validErr = []
    {data.isValidationErrorNode && data.validationErrors && Array.isArray(data.validationErrors) && data.validationErrors.map((error, index) => (
      validErr.push(error.data.label)
    ))}
    setFieldList(validErr)
  }, [])

  return (

    <div style={styles.container}>
      <div style={styles.topSection}>
        <div style={styles.iconContainer}>{data.icon}</div>
        <div style={styles.contentContainer}>
          <div style={styles.title}>{data.label}</div>
          <div style={styles.description}>{data.description}</div>
          {fieldList.map((field) => <div style={styles.description}>- {field}</div>)}
        </div>
      </div>
      {data.eventDateTime &&
      <div style={styles.eventDateTimeContainer}>
        <div style={styles.eventDateTime}>
          {formatDate(data.eventDateTime)}
        </div>
      </div>
      }
    </div>
  );
};

const styles = {
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    borderRadius: '8px',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#F5F7FA',
    border: '1px solid #EBEDEF',
    padding: '12px',
    width: '250px',
    minHeight: '70px',
  },
  topSection: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '24px',
    marginRight: '8px',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexGrow: 1,
  },
  title: {
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '1.2',
    marginBottom: '4px',
    textAlign: 'left',
    color: '#2C2E34',
  },
  description: {
    fontSize: '0.9rem',
    color: '#6E7181',
    textAlign: 'left',
  },
  eventDateTimeContainer: {
    position: 'absolute',
    right: '12px',
    bottom: '12px',
  },
  eventDateTime: {
    fontSize: '0.8rem',
    fontWeight: 400,
    color: '#9A9CA5',
    marginBottom: '-5px'
  },
};

export default AnalyticsNode;
