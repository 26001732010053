import axios from 'axios';
import config from '../config/config';
import { getCommonHeaders } from './common-api-utils';

const {
  workflows: { workflowPath, tenantWorkflowPath },
  api: { middlewareAPIURL },
} = config;

export async function getWorkflows() {
  const result = await axios.get(`${middlewareAPIURL}${workflowPath}`, { headers: getCommonHeaders() });

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}

export async function createWorkflow(data = {}) {
  if (Object.keys(data).length) {
    const result = await axios.post(`${middlewareAPIURL}${workflowPath}`, { ...data }, { headers: getCommonHeaders() });

    if (result.status === 200 || result.status === 201) return result.data;
  }

  return null;
}

export async function updateWorkflow(data = {}) {
  if (Object.keys(data).length) {
    const result = await axios.put(`${middlewareAPIURL}${workflowPath}`, { ...data }, { headers: getCommonHeaders() });

    if (result.status === 200 || result.status === 201) return result.data;
  }

  return null;
}

export async function deleteWorkflow(id) {
  if (id) {
    const result = await axios.delete(`${middlewareAPIURL}${workflowPath}/${id}`, { headers: getCommonHeaders() });

    if (result.status === 201 || result.status === 200) return result.data;
  }

  return null;
}

export async function getTenantWorkflow() {
  const result = await axios.get(`${middlewareAPIURL}${tenantWorkflowPath}`, { headers: getCommonHeaders() });

  if (result.status === 201 || result.status === 200) return result.data;

  return null;
}
