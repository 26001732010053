import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import { toast } from 'react-toastify';
import { useMounted, usePageLoading } from '../../../Base/hooks';
import { DataTable, useTableState } from '../../../Base/Tables';
import { ClientAdminNoDataMessage, utils, PageColumn } from '../Dashboard';
import { ActionButton } from '../../../Base/Buttons';
import { TippyTruncate } from '../../../Base/Truncate';
import { Confirmation } from '../../../Base/Modal';
import { deleteObjFromArray } from '../../../../js/utils/arrayOfObjects';
import { retryableAPICall } from '../../../../api/common-api-utils';
import { getWorkflows, deleteWorkflow } from '../../../../api/WorkflowAPI';
import Select from 'react-select';
import { checkPermissions } from '../../../../js/auth/AuthUtils';
import WorkflowCreatePanel from './WorkflowCreatePanel';
import WorkflowEditPanel from './WorkflowEditPanel';
import { Input } from 'reactstrap';

const triggerTypes = [
  { label: 'eDocument Status Change', value: 'EDOC_STATUS_CHANGE' },
  { label: 'Event Status Change', value: 'EVENT_STATUS_CHANGE' },
  { label: 'FlexiForm Status Change', value: 'GENERIC_FORM_STATUS_CHANGE' },
  { label: 'Funnel Status Change', value: 'FUNNEL_STATE_CHANGE' },
  { label: 'New Candidate', value: 'CREATE_CANDIDATE_EVENT' },
  { label: 'Onboarding Status Change', value: 'ONBOARDING_STATUS_CHANGE' },
  { label: 'Payroll submission', value: 'PAYROLL_INTEGRATION_SUBMISSION' },
  { label: 'Personality Test Status Change', value: 'PERSONALITY_TEST_STATUS_CHANGE' },
  { label: 'Reference Status Change', value: 'REFERENCE_STATUS_CHANGE' },
  { label: 'Referee Status Change', value: 'REFEREE_STATUS_CHANGE' },
  { label: 'Right To Work Status Change', value: 'RTW_STATUS_CHANGE' },
];

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

async function deleteAction(id, onSuccess = () => {}, onError = () => {}) {
  if (id) {
    const resp = await retryableAPICall(() => deleteWorkflow(id));

    if (typeof resp === 'string' && resp.length) {
      onError();
    } else {
      onSuccess();
    }
  }
}

function Workflows({ isCreateMode, onClose, totalAccounts, activeAccountId, tablePrefs }) {
  const isMounted = useMounted();
  const { pageState, setPageResolved, setPageRejected } = usePageLoading();
  const { tableState, setTableData, setTableRejected } = useTableState({ rowsPerPage: tablePrefs.pageSize });
  const [showConfirm, setShowConfirm] = useState(false);
  const [actionMenuId, setActionMenuId] = useState('');
  const [deleteObj, setDeleteObj] = useState();
  const [workflowObj, setWorkflowObj] = useState({});
  const [pageViewState, setPageViewState] = useState('VIEW');
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedType, setSelectedType] = useState({ value: 'ALL', label: 'All' });
  const [allDataLength, setAllDataLength] = useState(0);
  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const openWorkflowEditor = (workflow) => {
    setWorkflowObj({ name: 'Blank Workflow', nodes: [], edges: [] });
    setPageViewState('EDIT');
    onClose();
  };

  const createWorkflow = (workflow) => {
    setWorkflowObj(workflow);
    setPageViewState('EDIT');
    onClose();
  };

  const requestData = useCallback(
    async (callback = () => {}) => {
      const resp = await retryableAPICall(() => getWorkflows());

      if (isMounted()) {
        if (typeof resp === 'string') {
          setTableRejected();
          callback(resp);
        } else {
          const filteredData = resp.filter((workflow) => {
            return (
              workflow.name.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) &&
              (selectedType.value === 'ALL' || workflow.type === selectedType.value)
            );
          });
          setTableData({
            data: filteredData,
            totalResults: filteredData.length,
          });
          setAllDataLength(resp.length);
          callback();
        }
      }
    },
    [debouncedSearchTerm, isMounted, setTableData, setTableRejected, selectedType],
  );

  useEffect(() => {
    requestData((errorStatus) => {
      if (errorStatus) {
        setPageRejected(errorStatus);
      } else {
        setPageResolved();
      }
    });
  }, [requestData, setPageRejected, setPageResolved]);

  useEffect(() => {
    if (isCreateMode) {
      setPageViewState('CREATE');
    }
  }, [isCreateMode]);

  const columns = [
    {
      Header: 'Workflow Name',
      id: 'actionName',
      resizable: false,
      sortable: false,
      filterable: false,
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => {
        // eslint-disable-next-line react/prop-types
        const { name, accountId, readOnly } = original;

        if (checkPermissions(['admin:workflow:update']) && utils.isEditable(accountId, activeAccountId, readOnly)) {
          return (
            <a
              href="#workflowDetails"
              onClick={(e) => {
                e.preventDefault();
                setWorkflowObj({ name: 'Blank Workflow', nodes: [], edges: [] });
                setPageViewState('EDIT');
                onClose();
              }}
            >
              {name}
            </a>
          );
        }

        return name;
      },
    },
    {
      Header: 'Trigger Type',
      id: 'tiggerType',
      resizable: false,
      sortable: false,
      filterable: false,
      // eslint-disable-next-line react/prop-types
      Cell: ({ row: { original } }) => {
        // eslint-disable-next-line react/prop-types
        const { type } = original;
        const triggerObj = triggerTypes.find(({ value }) => value === type);
        if (triggerObj) return triggerObj.label;
        return '';
      },
    },
  ];

  if (totalAccounts > 1) {
    columns.push(
      {
        Header: 'EDITABLE',
        id: 'readOnly',
        width: 100,
        resizable: false,
        sortable: false,
        filterable: false,
        headerClassName: 'text-center',
        className: 'text-center',
        accessor: (r) => (r.readOnly ? 'No' : 'Yes'),
      },
      {
        Header: 'SHARED WITH',
        id: 'share',
        width: 200,
        resizable: false,
        sortable: false,
        filterable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => {
          // eslint-disable-next-line react/prop-types
          let { sharedWith = [] } = original;

          // eslint-disable-next-line react/prop-types
          sharedWith = sharedWith.filter(({ accountId }) => accountId !== activeAccountId);

          if (!sharedWith.length) return null;

          if (sharedWith.length === totalAccounts - 1) {
            return 'All';
          }

          const accountNames = sharedWith.map(({ accountName }) => accountName).join(', ');

          return <TippyTruncate>{accountNames}</TippyTruncate>;
        },
      },
    );
  }

  if (checkPermissions(['admin:workflow:update', 'admin:workflow:delete'])) {
    columns.push({
      id: 'action',
      resizable: false,
      sortable: false,
      filterable: false,
      width: 63,
      className: 'action-cell',
      Cell: ({ row: { original } }) => {
        const { id, name, readOnly, accountId } = original;

        const editable = utils.isEditable(accountId, activeAccountId, readOnly);
        const deletable = utils.isDeletable(accountId, activeAccountId);

        const menuItems = [
          {
            id: 0,
            label: 'Edit',
            icon: 'Pencil',
            permissions: ['admin:workflow:update'],
            action: () => {
              openWorkflowEditor(original);
            },
          },
          {
            id: 1,
            label: 'Delete',
            icon: 'Bin',
            permissions: ['admin:workflow:delete'],
            isDisabled: !deletable,
            tooltipContent: 'Only the owner can delete this',
            action: () => {
              setDeleteObj({ id, name });
              setActionMenuId('');
              setShowConfirm(true);
            },
          },
        ];

        return (
          <ActionButton
            isDisabled={!editable}
            isOpen={actionMenuId === id}
            menuItems={menuItems}
            menuStyle={{ width: '150px' }}
            onToggle={(isOpen) => setActionMenuId(isOpen ? id : '')}
            position="left"
            subMenuProps={{
              menuStyle: { width: '100px' },
              position: 'left',
            }}
            title="Action"
            tooltipContent="Only the owner can edit this"
          />
        );
      },
    });
  }

  if (pageViewState === 'EDIT') {
    return (
      <WorkflowEditPanel
        workflowObj={workflowObj}
        setWorkflowObj={setWorkflowObj}
        goBackToEdit={() => {
          setPageViewState('VIEW');
          onClose();
        }}
      />
    );
  }

  if (pageViewState === 'CREATE') {
    return (
      <WorkflowCreatePanel
        onCreateWorkflow={createWorkflow}
        goBackToEdit={() => {
          setPageViewState('VIEW');
          onClose();
        }}
      />
    );
  }

  const cancelCallback = () => {
    setShowConfirm(false);
    setDeleteObj();
  };

  const confirmCallback = () => {
    deleteAction(
      deleteObj.id,
      () => {
        toast.success('Workflow successfully deleted');

        const updatedTableData = deleteObjFromArray(tableState.data, deleteObj.id);

        setTableData({
          data: updatedTableData,
          totalResults: updatedTableData.length,
        });
      },
      () => {
        toast.error('Error deleting workflow');
      },
    );

    setShowConfirm(false);
  };

  return (
    <>
      <PageColumn
        className={cx('pt-3 overflow-visible', { 'h-scroll-table': tableState.data.length })}
        state={pageState}
      >
        <div className="d-flex flex-column w-100 ">
          <div className="d-flex align-items-center gap-3">
            <Input
              value={searchTerm}
              className="w-25"
              type="text"
              placeholder="Search Workflows by Name"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="d-flex" style={{ width: '400px' }}>
              <label className="me-2 mt-auto mb-auto">Type:</label>
              <Select
                classNamePrefix="react-select"
                placeholder="Select Form"
                className="flex-grow-1"
                value={selectedType}
                options={[{ value: 'ALL', label: 'All' }, ...triggerTypes]}
                onChange={(value) => {
                  setSelectedType(value);
                }}
              />
            </div>
          </div>

          {!tableState.data.length ? (
            <ClientAdminNoDataMessage
              btnProps={{
                onClick: () => setPageViewState('CREATE'),
                label: allDataLength > 0 ? null : 'Create Automated Workflow',
              }}
              message=""
              title={allDataLength > 0 ? 'No Workflows match the filter' : 'You currently have no Workflows set up'}
            />
          ) : (
            <DataTable
              className="mt-3"
              data={tableState.data}
              errorText="There has been an error loading Workflows, please try again later"
              hasSelectColumn={false}
              id="workflows"
              isLoading={tableState.isLoading}
              isRejected={tableState.isRejected}
              noDataText="You currently have no Workflows"
              pageSize={tableState.rowsPerPage}
              showPagination={false}
              columns={columns}
              totalResults={tableState.totalResults}
            />
          )}
        </div>
      </PageColumn>
      <Confirmation
        cancelCallback={cancelCallback}
        confirmCallback={confirmCallback}
        content={`Are you sure you want to delete ${(deleteObj || {}).name || ''} workflow?`}
        show={showConfirm}
      />
    </>
  );
}

Workflows.propTypes = {
  isCreateMode: PropTypes.bool,
  onClose: PropTypes.func,
  totalAccounts: PropTypes.number,
  activeAccountId: PropTypes.string,
  tablePrefs: PropTypes.shape({
    pageSize: PropTypes.number,
  }),
};

Workflows.defaultProps = {
  isCreateMode: false,
  totalAccounts: 0,
  activeAccountId: null,
  onClose: () => {},
  tablePrefs: {
    pageSize: 25,
  },
};

function mapStateToProps(state) {
  const {
    tablePrefs,
    userData: {
      userDetails: {
        data: { accountAccess = [] },
        activeAccountId,
      },
    },
  } = state;

  return {
    tablePrefs,
    totalAccounts: accountAccess.length,
    activeAccountId,
  };
}

export default connect(mapStateToProps)(Workflows);
