/* eslint-disable jsx-a11y/label-has-for */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';
import styled from 'styled-components';
import { PERMISSIONS } from './permissions';
import MatrixCheckboxes from './MatrixCheckboxes';
import { userPermissionFeatures, shortestArray, createPermissions } from './utils';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 150px repeat(4, 1fr);
`;

const GridDivider = styled.div`
  background-color: white;
  grid-column-start: 1;
  grid-column-end: 6;

  ${({ height = '2em' }) => `
    height: ${height};
  `}
`;

const TitleCell = styled.div`
  text-align: center;
`;

const FeatureName = styled.div`
  text-transform: capitalize;

  &.rtw,
  &.gdpr,
  &.pt,
  &.sms {
    text-transform: uppercase;
  }
`;

function PermissionsMatrix(props) {
  const {
    schema: { title },
    idSchema: { $id: id },
    formContext: { roles },
    onChange,
    formData,
  } = props;

  const userFeatures = userPermissionFeatures(roles || []);
  const selectedPerms = userPermissionFeatures(formData || []);

  function handleChange(cbId, checked, subType, feature, section = 'self') {
    let crudType = cbId;
    if (cbId === 'view') crudType = subType || cbId;
    if (cbId === 'create') crudType = subType || cbId;
    if (cbId === 'update') crudType = subType || cbId;

    const permissionStr = createPermissions(feature, section, crudType);

    // publish uses create and write
    const isPublishCreate = feature === 'publish' && section === 'self' && crudType === 'create';
    const publishWritePermStr = createPermissions(feature, section, 'write');

    let updatedPerms = [...formData];

    if (checked) {
      updatedPerms.push(permissionStr);
      // add publish:write
      if (isPublishCreate) updatedPerms.push(publishWritePermStr);
    } else {
      updatedPerms = updatedPerms.filter((str) => str !== permissionStr);
      // remove publish:write
      if (isPublishCreate) updatedPerms = updatedPerms.filter((str) => str !== publishWritePermStr);
    }

    onChange(updatedPerms);
  }

  return (
    <FormGroup>
      <Label for={id}>{title}</Label>
      <div className="pm-grid-wrapper">
        <Grid className="pm-grid pm-grid-header">
          <GridDivider height="1em" />
          <TitleCell className="pm-grid-head-cell pm-grid-start-cell">Feature</TitleCell>
          <TitleCell className="pm-grid-head-cell">View</TitleCell>
          <TitleCell className="pm-grid-head-cell">Create</TitleCell>
          <TitleCell className="pm-grid-head-cell">Update</TitleCell>
          <TitleCell className="pm-grid-head-cell">Delete</TitleCell>
        </Grid>
        <Grid className="pm-grid pm-grid-inputs">
          {Object.entries(PERMISSIONS).map(([feature, cfg], i) => {
            const { sections, self: slfCrud } = cfg;
            if (!userFeatures[feature]) return null;

            // stops user being able to apply permissions they don't have
            const slfCrudArr = shortestArray(userFeatures[feature].self || [], slfCrud);

            let featureValue = [];
            if (selectedPerms[feature]) {
              featureValue = selectedPerms[feature].self;
            }

            return (
              <Fragment key={feature}>
                <GridDivider height={i === 0 ? '1em' : '2em'} />
                <FeatureName className={`${feature} pm-grid-cell pm-grid-start-cell pm-grid-top-cell`}>
                  <h6 className="mb-0">{feature}</h6>
                </FeatureName>
                <MatrixCheckboxes
                  className="pm-grid-cell pm-grid-top-cell"
                  crud={slfCrudArr}
                  id={feature}
                  onChange={(fnc, checked, subType) => handleChange(fnc, checked, subType, feature)}
                  value={featureValue}
                />
                {Object.entries(sections).map(([section, crud]) => {
                  const idStr = `${feature}-${section}`;
                  // stops user being able to apply permissions they don't have
                  const crudArr = shortestArray(userFeatures[feature].sections[section] || [], crud);

                  let sectionValue = [];
                  if (selectedPerms[feature]) {
                    sectionValue = selectedPerms[feature].sections[section];
                  }

                  return (
                    <Fragment key={idStr}>
                      <FeatureName className={`${section} pm-grid-cell pm-grid-start-cell`}>{section}</FeatureName>
                      <MatrixCheckboxes
                        className="pm-grid-cell"
                        crud={crudArr}
                        id={idStr}
                        onChange={(fnc, checked, subType) => handleChange(fnc, checked, subType, feature, section)}
                        value={sectionValue}
                      />
                    </Fragment>
                  );
                })}
              </Fragment>
            );
          })}
        </Grid>
      </div>
    </FormGroup>
  );
}

PermissionsMatrix.propTypes = {
  schema: PropTypes.shape({
    title: PropTypes.string,
  }),
  idSchema: PropTypes.shape({
    $id: PropTypes.string,
  }),
  formContext: PropTypes.shape({
    roles: PropTypes.arrayOf(PropTypes.string),
  }),
  onChange: PropTypes.func,
  formData: PropTypes.arrayOf(PropTypes.string),
};

PermissionsMatrix.defaultProps = {
  schema: {},
  idSchema: {},
  formContext: {
    roles: [],
  },
  onChange: () => {},
  formData: [],
};

export default PermissionsMatrix;
