import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactQuill, { Quill } from 'react-quill';
import { toast } from 'react-toastify';
import WrapVideo from './wrap-video';
import 'react-quill/dist/quill.snow.css';
import { removeControlsFromSource } from './resize-video';

WrapVideo.blotName = 'video';
WrapVideo.className = 'ql-video-wrapper';
WrapVideo.tagName = 'DIV';

// eslint-disable-next-line new-cap
const AlignStyle = Quill.import('attributors/style/align');
const SizeStyle = Quill.import('attributors/style/size');
Quill.register('formats/video', WrapVideo);
Quill.register(AlignStyle, true);
Quill.register(SizeStyle, true);
function fileWarning() {
  const input = document.querySelector('.ql-image[type="file"]');
  const files = input ? input.files : [];

  if (files.length) {
    const totalSize = [...files].reduce((acc, { size = 0 }) => acc + size, 0);
    if (totalSize > 1000000) {
      // eslint-disable-next-line max-len
      toast.error(
        "Sorry the images you've included should be no more than 1MB in size. Please reduce the image size and try again",
      );
    }
  }
}

function imageHandler() {
  const { tooltip } = this.quill.theme;
  const originalSave = tooltip.save;
  const originalHide = tooltip.hide;
  // eslint-disable-next-line func-names
  tooltip.save = function () {
    const range = this.quill.getSelection(true);
    const { value } = this.textbox;
    if (value) {
      this.quill.insertEmbed(range.index, 'image', value, 'user');
    }
  };
  // Called on hide and save.
  tooltip.hide = () => {
    tooltip.save = originalSave;
    tooltip.hide = originalHide;
    tooltip.hide();
  };
  tooltip.edit('image');
  tooltip.textbox.placeholder = 'Embed URL';
}

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
const modules = {
  toolbar: {
    container: [
      [{ header: [1, 2, 3, 4, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote', { color: [] }],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
        { align: '' },
        { align: 'center' },
        { align: 'right' },
      ],
      ['link', 'video', 'image'],
    ],
    handlers: {
      image: imageHandler,
    },
  },

  // [
  //   [{ header: [1, 2, 3, 4, false] }],
  //   ['bold', 'italic', 'underline', 'strike', 'blockquote', { color: [] }],
  //   [{ list: 'ordered' }, { list: 'bullet' },
  //     { indent: '-1' }, { indent: '+1' },
  //     { align: '' }, { align: 'center' }, { align: 'right' },
  //   ],
  //   ['link', 'video', 'image'],
  //   [],
  // ],
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const formats = [
  'header',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'align',
  'link',
  'video',
  'image',
  'color',
];

class RTE extends Component {
  constructor(props) {
    super(props);
    const { formData } = props;
    this.state = { formData };
    this.editor = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { formData } = this.props;
    if (prevProps.formData !== formData) {
      this.onUpdate(formData);
    }
  }

  onUpdate = (value) => {
    this.setState({ formData: value });
  };

  handleChange = (content) => {
    const { onChange } = this.props;
    fileWarning();
    let formData = content?.replace(/&nbsp;/g, ' ');
    if (formData === '<p><br></p>') formData = null;
    this.setState({ formData });
    // remove the controls from the html stored on the server
    onChange(removeControlsFromSource(formData));
  };

  render() {
    const {
      schema: { title },
      uiSchema,
      required,
    } = this.props;
    const { formData } = this.state;

    return (
      <Fragment>
        {title && (
          <label className="control-label" htmlFor="root_referenceCode">
            {title}
            {required && <span className="required">*</span>}
          </label>
        )}
        <ReactQuill
          ref={this.editor}
          value={formData || ''}
          onChange={this.handleChange}
          modules={uiSchema.modules || modules}
          formats={formats}
          readOnly={uiSchema && uiSchema['ui:readonly']}
          className={uiSchema && uiSchema['ui:readonly'] ? 'disabled' : ''}
        />
      </Fragment>
    );
  }
}

RTE.propTypes = {
  onChange: PropTypes.func,
  formData: PropTypes.string,
  schema: PropTypes.shape({
    title: PropTypes.string,
  }).isRequired,
  uiSchema: PropTypes.shape({
    modules: PropTypes.shape(),
  }).isRequired,
  required: PropTypes.bool,
};

RTE.defaultProps = {
  onChange: () => {},
  formData: null,
  required: false,
};

export default RTE;
