export function userPermissionFeatures(roles) {
  const featureObj = roles.reduce((acc, role) => {
    let features = { ...acc };
    const permissions = role.split(':');

    if (permissions.length) {
      let [feature, section, crud = 'allow'] = permissions;

      const isIntegrations = /^(fourth|polaris|peoplehr|tamigo|s4|accessgrp|rota|sap-nl)$/.test(feature);

      // compile integrations into single object
      if (isIntegrations) {
        crud = section;
        section = feature;
        feature = 'integrations';
      }

      const isCRUD = /^(create|read|update|delete|write|view|preview|admin|company|allow|plus|readonly)$/.test(crud);
      let isSelf = /^(create|read|update|delete|write|view|preview|admin|plus)$/.test(section);

      // we have confused the system, create should not be a section
      if (role === 'vacancy:create:readonly') {
        isSelf = false;
      }

      // add feature if doesn't exist
      if (!features[feature]) features = { ...features, [feature]: { self: [], sections: {} } };

      // update self array with CRUD permissions
      if (!features[feature].self.includes(section) && isSelf) {
        features = {
          ...features,
          [feature]: {
            ...features[feature],
            self: [...features[feature].self, section],
          },
        };
      }

      // add section if not already added
      if (!features[feature].sections[section] && !isSelf) {
        features = {
          ...features,
          [feature]: {
            ...features[feature],
            sections: {
              ...features[feature].sections,
              [section]: [],
            },
          },
        };
      }

      // if crud permission not in section array add it
      if (features[feature].sections[section] && !isSelf && isCRUD) {
        features = {
          ...features,
          [feature]: {
            ...features[feature],
            sections: {
              ...features[feature].sections,
              [section]: [...features[feature].sections[section], crud],
            },
          },
        };
      }
    }

    return features;
  }, {});

  return featureObj;
}

export function shortestArray(arr1, arr2) {
  if (arr1.length < arr2.length) return [...arr1];
  return [...arr2];
}

export function createPermissions(feature, section, crud) {
  if (section === 'self') return `${feature}:${crud}`;
  if (crud === 'allow') return `${feature}:${section}`;
  if (feature === 'integrations' || crud === 'admin') return `${section}:${crud}`;
  return `${feature}:${section}:${crud}`;
}
