/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  Label,
  Col,
  FormText,
} from 'reactstrap';
import Tippy from '@tippyjs/react';
import Required from '../Required';
import ErrorFeedback from '../../../ErrorFeedback/ErrorFeedback';
import { ConditionalWrapper } from '../../../SectionDividers';
import { IconSVG } from '../../../SVG';

function CommonFormGroup({
  id,
  label,
  row,
  className,
  required,
  colProps,
  labelProps,
  check,
  labelSibling,
  error,
  helpText,
  helpIconText,
  children,
}) {
  return (
    <FormGroup row={row} className={className} check={check}>
      <ConditionalWrapper
        condition={labelSibling && !row}
        wrapper={(child) => <div className="d-flex align-items-center">{child}</div>}
      >
        <Label {...labelProps} for={id}>
          {`${label}${row ? ':' : ''}`}
          {required && <Required />}
          {helpIconText && (
          <Tippy content={helpIconText}>
            <span style={{ position: 'relative', left: '5px', top: '-2px' }}>
              <IconSVG name="Help" />
            </span>
          </Tippy>
          )}
        </Label>

        {labelSibling}
      </ConditionalWrapper>
      <ConditionalWrapper
        condition={row}
        wrapper={(child) => <Col {...colProps}>{child}</Col>}
      >
        {children}
        <ErrorFeedback message={error} />
        {helpText && <FormText color="muted">{helpText}</FormText>}
      </ConditionalWrapper>
    </FormGroup>
  );
}

CommonFormGroup.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  row: PropTypes.bool,
  className: PropTypes.string,
  helpIconText: PropTypes.string,
  required: PropTypes.bool,
  colProps: PropTypes.shape(),
  labelProps: PropTypes.shape(),
  check: PropTypes.bool,
  labelSibling: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  error: PropTypes.string,
  helpText: PropTypes.string,
};

CommonFormGroup.defaultProps = {
  row: false,
  className: null,
  required: false,
  colProps: {},
  labelProps: {},
  check: false,
  labelSibling: null,
  helpIconText: null,
  error: null,
  helpText: null,
};

export default CommonFormGroup;
