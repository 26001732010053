import { memo } from 'react';
import { NodeResizer } from 'reactflow';

const AnnotationNode = ({ data }) => {
  return (
    <>
      <NodeResizer minWidth={100} minHeight={30} />
      <div style={{ padding: 10, wordBreak: 'break-all' }}>{data.label}</div>
    </>
  );
};

export default memo(AnnotationNode);
