const actionNodes = [
  { type: 'actionNode', label: 'Request Onboarding', actionType: 'REQUEST_ONBOARDING' },
  { type: 'actionNode', label: 'Request Referees', actionType: 'REQUEST_REFEREES' },
  { type: 'actionNode', label: 'Send to Ubeya', actionType: 'SEND_TO_UBEYA' },
  { type: 'actionNode', label: 'Send to Flow', actionType: 'SEND_TO_FLOW' },
  {
    type: 'actionNode',
    label: 'Specific Send FlexiForm Request',
    actionType: 'SPECIFIC_SEND_FLEXIFORM_REQUEST',
  },
  { type: 'actionNode', label: 'Send to SMART', actionType: 'SEND_TO_SMART' },
  {
    type: 'actionNode',
    label: 'Request Right to Work Docs',
    actionType: 'REQUEST_RIGHT_TO_WORK_DOCS',
  },
  {
    type: 'actionNode',
    label: 'Specific eDocuments Request',
    actionType: 'SPECIFIC_EDOCUMENTS_REQUEST',
  },
  {
    type: 'actionNode',
    label: 'Request Personality Test',
    actionType: 'REQUEST_PERSONALITY_TEST',
  },
  {
    type: 'actionNode',
    label: 'Send Candidate Contact Email',
    actionType: 'SEND_CANDIDATE_CONTACT_EMAIL',
  },
  { type: 'actionNode', label: 'Update Funnel Stage', actionType: 'UPDATE_FUNNEL_STAGE' },
  { type: 'actionNode', label: 'Request References', actionType: 'REQUEST_REFERENCES' },
  { type: 'actionNode', label: 'Request DBS', actionType: 'REQUEST_DBS' },
  { type: 'actionNode', label: 'Add / Remove Tags', actionType: 'ADD_REMOVE_TAGS' },
  { type: 'actionNode', label: 'Request Specific Edoc', actionType: 'REQUEST_SPECIFIC_EDOC' },
  { type: 'actionNode', label: 'Send User Email', actionType: 'SEND_USER_EMAIL' },
  { type: 'actionNode', label: 'Send Reminders', actionType: 'SEND_REMINDERS' },
  { type: 'actionNode', label: 'Send SMS', actionType: 'SEND_SMS' },
  { type: 'actionNode', label: 'Add to Talent Pool', actionType: 'ADD_TO_TALENT_POOL' },
];

const delayNodes = [{ type: 'waitNode', label: 'Wait', actionType: 'WAIT' }];

const decisionNodes = [{ type: 'decisionNode', label: 'Decision', actionType: 'DECISION', iconColour: 'inherit' }];

const notesNodes = [{ type: 'annotationNode', label: 'Annotation', actionType: 'ANNOTATION' }];
const exitNodes = [{ type: 'endNode', label: 'Exit Workflow', actionType: 'EXIT' }];

const inputNodes = [
  {
    type: 'startNode',
    label: 'eDocument Status Change',
    actionType: 'EDOC_STATUS_CHANGE',
    actions: ['SEND_CANDIDATE_CONTACT_EMAIL', 'UPDATE_FUNNEL_STAGE'],
  },
  {
    type: 'startNode',
    label: 'Specific Event Status Change',
    actionType: 'EVENT_STATUS_CHANGE',
    actions: [
      'REQUEST_ONBOARDING',
      'REQUEST_REFEREES',
      'SEND_TO_UBEYA',
      'SEND_TO_FLOW',
      'SPECIFIC_SEND_FLEXIFORM_REQUEST',
      'SEND_TO_SMART',
      'REQUEST_RIGHT_TO_WORK_DOCS',
      'SPECIFIC_EDOCUMENTS_REQUEST',
      'REQUEST_PERSONALITY_TEST',
      'SEND_CANDIDATE_CONTACT_EMAIL',
      'UPDATE_FUNNEL_STAGE',
    ],
  },

  {
    type: 'startNode',
    label: 'Specific FlexiForm Status Change',
    actionType: 'FLEXIFORM_STATUS_CHANGE',
    actions: [
      'UPDATE_FUNNEL_STAGE',
      'REQUEST_ONBOARDING',
      'REQUEST_REFEREES',
      'REQUEST_RIGHT_TO_WORK_DOCS',
      'SPECIFIC_EDOCUMENTS_REQUEST',
      'SEND_CANDIDATE_CONTACT_EMAIL',
    ],
  },
  {
    type: 'startNode',
    label: 'Funnel Status Change',
    actionType: 'FUNNEL_STATUS_CHANGE',
    actions: [
      'REQUEST_ONBOARDING',
      'REQUEST_REFEREES',
      'SEND_TO_UBEYA',
      'SEND_TO_FLOW',
      'SPECIFIC_SEND_FLEXIFORM_REQUEST',
      'SEND_TO_SMART',
      'REQUEST_RIGHT_TO_WORK_DOCS',
      'SPECIFIC_EDOCUMENTS_REQUEST',
      'REQUEST_PERSONALITY_TEST',
      'SEND_CANDIDATE_CONTACT_EMAIL',
      'UPDATE_FUNNEL_STAGE',
    ],
  },
  {
    type: 'startNode',
    label: 'New Candidate',
    actionType: 'NEW_CANDIDATE',
    actions: [
      'REQUEST_ONBOARDING',
      'REQUEST_REFEREES',
      'SEND_TO_UBEYA',
      'SEND_TO_FLOW',
      'SPECIFIC_SEND_FLEXIFORM_REQUEST',
      'SEND_TO_SMART',
      'REQUEST_RIGHT_TO_WORK_DOCS',
      'SPECIFIC_EDOCUMENTS_REQUEST',
      'REQUEST_PERSONALITY_TEST',
      'SEND_CANDIDATE_CONTACT_EMAIL',
    ],
  },
  {
    type: 'startNode',
    label: 'Onboarding Status Change',
    actionType: 'ONBOARDING_STATUS_CHANGE',
    actions: ['UPDATE_FUNNEL_STAGE', 'REQUEST_REFEREES', 'SEND_CANDIDATE_CONTACT_EMAIL'],
  },
  {
    type: 'startNode',
    label: 'Payroll Submission',
    actionType: 'PAYROLL_SUBMISSION_STATUS_CHANGE',
    actions: ['UPDATE_FUNNEL_STAGE', 'REQUEST_REFEREES', 'SEND_CANDIDATE_CONTACT_EMAIL'],
  },
  {
    type: 'startNode',
    label: 'Personality Test Status Change',
    actionType: 'PT_STATUS_CHANGE',
    actions: ['UPDATE_FUNNEL_STAGE', 'SEND_CANDIDATE_CONTACT_EMAIL'],
  },
  {
    type: 'startNode',
    label: 'Referee Status Change',
    actionType: 'REFEREE_STATUS_CHANGE',
    actions: ['UPDATE_FUNNEL_STAGE', 'REQUEST_REFERENCES', 'SEND_CANDIDATE_CONTACT_EMAIL'],
  },
  {
    type: 'startNode',
    label: 'Reference Status Change',
    actionType: 'REFERENCE_STATUS_CHANGE',
    actions: ['UPDATE_FUNNEL_STAGE', 'SEND_CANDIDATE_CONTACT_EMAIL'],
  },
  {
    type: 'startNode',
    label: 'Right To Work Status Change',
    actionType: 'RTW_STATUS_CHANGE',
    actions: ['UPDATE_FUNNEL_STAGE', 'SEND_CANDIDATE_CONTACT_EMAIL'],
  },
];

// These aren't avaible yet but might be by the end of this
const possibleTrigger = [
  { type: 'startNode', label: '??? DBS Status Change', actionType: 'DBS_STATUS_CHANGE', actions: [] },
  { type: 'startNode', label: '??? Tag added / removed', actionType: 'TAG_CHANGE', actions: [] },
  {
    type: 'startNode',
    label: '??? Answer on Application Form',
    actionType: 'ANSWER_ON_THE_APPLICATION_FORM',
    actions: [],
  },
  { type: 'startNode', label: '??? More than one Application', actionType: 'MORE_THAN_ONE_REASON', actions: [] },
  { type: 'startNode', label: '??? Rejection Reason', actionType: 'REJECTION_REASON', actions: [] },
  { type: 'startNode', label: '??? How long a candidate', actionType: 'EDOC_STATUS_CHANGE', actions: [] },
];

export { actionNodes, delayNodes, decisionNodes, notesNodes, exitNodes, inputNodes };
