import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from '../../../../../../Base/Slider/Slider';
import { Confirmation } from '../../../../../../Base/Modal';
import { CreateButton, CancelButton } from '../../../../../../Base/Buttons';

function ExitConditionsSlider({ isSliderOpen, closeSlider, onSave }) {
  const [showConfirm, setShowConfirm] = useState(false);

  return (
    <Fragment>
      <Slider
        title="Exit Conditions"
        isOpen={isSliderOpen}
        toggleOpen={(isBgClick) => {
          if (isBgClick) {
            setShowConfirm(true);
          } else {
            closeSlider();
          }
        }}
        focusForm={false}
        width="100%"
        pauseNotificationsOnOpen
      >
        <div className="d-flex flex-column">
          <p>
            Exit conditions are used to determine when a candidate should be removed from the workflow. You can add
            multiple conditions to determine when a candidate should be removed from the workflow.
          </p>
          <div>
            <div className="d-flex mt-3">
              <input type="checkbox" id="endNode" name="endNode" value="true" checked disabled />
              <label style={{ fontWeight: 'bold', paddingLeft: '10px' }}>
                If the Candidate gets gets to an end node of the workflow
              </label>
            </div>
            <div className="d-flex mt-3">
              <input type="checkbox" id="endNode" name="endNode" value="true" />
              <label style={{ fontWeight: 'bold', paddingLeft: '10px' }}>If the user is in the hired stage</label>
            </div>
            <div className="d-flex my-3">
              <input type="checkbox" id="endNode" name="endNode" value="true" />
              <label style={{ fontWeight: 'bold', paddingLeft: '10px' }}>If the user is in the rejected stage</label>
            </div>
          </div>
        </div>
        <CreateButton
          className="mt-2"
          label={'Save'}
          floatRight
          action={(e) => {
            e.preventDefault();
            onSave(value);
          }}
        />
        <CancelButton
          className="mt-2 ms-2"
          floatRight
          action={() => {
            closeSlider(value);
          }}
        />
      </Slider>
      <Confirmation
        content="Your data will be lost if you close the slider"
        show={showConfirm}
        cancelCallback={() => setShowConfirm(false)}
        confirmCallback={() => {
          setShowConfirm(false);
          closeSlider();
        }}
      />
    </Fragment>
  );
}

ExitConditionsSlider.propTypes = {
  isSliderOpen: PropTypes.bool,
  closeSlider: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  title: PropTypes.string,
};

ExitConditionsSlider.defaultProps = {
  isSliderOpen: false,
  closeSlider: () => {},
  children: () => {},
  title: null,
};

export default ExitConditionsSlider;
