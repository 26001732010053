import PropTypes from 'prop-types';
import React from 'react';
import { getSubmission, submitToPeopleHR } from '../../../../api/Integrations/PeopleHRAPI';
import { retryableAPICall } from '../../../../api/common-api-utils';
import Integration from './Integration';

const PeopleHRInputConfig = [
  {
    id: 'startDate',
    label: 'Start Date',
    required: true,
    type: 'date',
    inputProps: {
      isPastDatesAllowed: true,
    },
  },
  {
    id: 'reportsTo',
    label: 'Reports To',
    type: 'email',
  },
  {
    id: 'jobRole',
    label: 'Job Role',
    type: 'select',
    required: true,
    url: 'integration/data/peoplehr/role',
    dataMapper: (data) => {
      return { label: data.label, value: data.value };
    },
  },
  {
    id: 'jobRoleEffectiveDate',
    label: 'Continuous Service Date',
    required: true,
    type: 'date',
    inputProps: {
      isPastDatesAllowed: true,
    },
  },
  {
    id: 'company',
    label: 'Company',
    type: 'select',
    url: 'integration/data/peoplehr/company',
    dataMapper: (data) => {
      return { label: data.label, value: data.value };
    },
  },
  {
    id: 'location',
    label: 'Location',
    required: true,
    type: 'select',
    url: 'integration/data/peoplehr/location',
    dataMapper: (data) => {
      return { label: data.label, value: data.value };
    },
  },
  {
    id: 'department',
    label: 'Department',
    required: true,
    type: 'select',
    url: 'integration/data/peoplehr/department',
    dataMapper: (data) => {
      return { label: data.label, value: data.value };
    },
  },
  {
    id: 'employmentType',
    label: 'Employment Type',
    type: 'select',
    url: 'integration/data/peoplehr/employmentType',
    dataMapper: (data) => {
      return { label: data.label, value: data.value };
    },
  },
  {
    id: 'entitlementThisYear',
    label: 'Entitlement This Year',
    type: 'number',
    defaultValue: 28,
    inputProps: {
      min: 0,
    },
  },
  {
    id: 'entitlementNextYear',
    label: 'Entitlement Next Year',
    type: 'number',
    defaultValue: 28,
    inputProps: {
      min: 0,
    },
  },
  {
    id: 'payrollCompany',
    label: 'Payroll Company',
    type: 'text',
  },
  {
    id: 'salaryType',
    label: 'Salary Type',
    type: 'select',
    required: true,
    options: [
      { label: 'Annual', value: 'Annual' },
      { label: 'Monthly', value: 'Monthly' },
      { label: 'Weekly', value: 'Weekly' },
      { label: 'Daily', value: 'Daily' },
      { label: 'Hourly', value: 'Hourly' },
    ],
  },
  {
    id: 'paymentFrequency',
    label: 'Payment Frequency',
    type: 'select',
    required: true,
    options: [
      { label: 'Monthly', value: 'Monthly' },
      { label: 'Four Weekly', value: 'Four Weekly' },
      { label: 'Two Weekly', value: 'Two Weekly' },
      { label: 'Fortnightly', value: 'Fortnightly' },
      { label: 'Weekly', value: 'Weekly' },
      { label: 'Daily', value: 'Daily' },
    ],
  },
  {
    id: 'salaryAmount',
    label: 'Salary Amount',
    required: true,
    type: 'number',
    inputProps: {
      min: 0,
    },
  },
];

function valueSetter(url, value, isDisabled, isComplete, type, formData, id) {
  let convertedValue = type === 'number' && !formData[id] ? 0 : type === 'checkbox' ? formData[id] === 1 : value;

  if (isComplete) {
    convertedValue = formData[id];
  }

  return { value: convertedValue, url, isDisabled, isHidden: false };
}

function PeopleHRForm({ applicantId }) {
  const formSubmit = async (applicantId, formData) => {
    try {
      const { status, message, submissionRequest } = await retryableAPICall(() =>
        submitToPeopleHR(applicantId, {
          dataFields: {
            startDate: formData.startDate,
            reportsTo: formData.reportsTo,
            jobRole: formData.jobRole,
            jobRoleEffectiveDate: formData.jobRoleEffectiveDate,
            company: formData.company,
            location: formData.location,
            department: formData.department,
            employmentType: formData.employmentType,
            entitlementThisYear: formData.entitlementThisYear,
            entitlementNextYear: formData.entitlementNextYear,
            payrollCompany: formData.payrollCompany,
            salaryType: formData.salaryType,
            paymentFrequency: formData.paymentFrequency,
            salaryAmount: formData.salaryAmount,
          },
        }),
      );
      return status === 'NOT_FOUND_ERROR'
        ? 'NOT_FOUND_ERROR'
        : { status: status === 'PENDING' ? 'FAILED' : status, message, data: submissionRequest?.dataFields };
    } catch (error) {
      console.error('Error during form submission:', error);
      return { status: 'ERROR', message: 'An unexpected error occurred during data fetching.' };
    }
  };

  const handleChange = (id, value, formData, setFormData) => {
    const updatedData = { ...formData, [id]: value };
    if (id === 'section' || id === 'subSection') {
      updatedData.subSection = id === 'section' ? null : updatedData.subSection;
      updatedData.post = null;
    }
    setFormData(updatedData);
  };

  async function getPeopleHRData(applicantId) {
    try {
      const resp = await retryableAPICall(() => getSubmission(applicantId));

      if (resp === '') {
        return 'NOT_FOUND_ERROR';
      }

      const { status, message, submissionRequest } = resp;

      if (status !== 'SUCCESS') {
        return {
          status: status,
          message: message,
          data: submissionRequest?.dataFields,
        };
      }

      return {
        status: 'SUCCESS',
        data: submissionRequest?.dataFields,
      };
    } catch (error) {
      console.error('Error fetching data:', error);
      return {
        status: 'ERROR',
        message: 'An unexpected error occurred during data fetching.',
      };
    }
  }

  const validationConfig = () => {
    return PeopleHRInputConfig.reduce((acc, { id, type, required, inputProps }) => {
      const { min, max } = inputProps || {};
      return [...acc, { id, required, type, ...(type === 'number' ? { type, min, max } : {}) }];
    }, []);
  };

  return (
    <Integration
      title="People HR"
      applicantId={applicantId}
      valueSetter={valueSetter}
      integrationName="PeopleHR Group"
      formConfig={PeopleHRInputConfig}
      formSubmit={formSubmit}
      getSubmission={getPeopleHRData}
      componentName="PeopleHR"
      customOnChange={handleChange}
      getValidationConfig={validationConfig}
    />
  );
}

PeopleHRForm.propTypes = {
  applicantId: PropTypes.string,
};

PeopleHRForm.defaultProps = {
  applicantId: null,
};

export default PeopleHRForm;
