import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';
import styled from 'styled-components';
import iconGetter from '../Icons';

const Container = styled.div`
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
`;

const Icon = styled.div`
  background-color: orange;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  display: flex;
  padding: 0.25rem;

  svg {
    margin: auto;
    fill: #fff;
  }
`;

const Text = styled.div`
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  text-align: left;
  font-size: 8px;
  display: flex;
  flex-direction: column;
  color: #1a2e56;

  .title {
    font-size: 12px;
    font-weight: bold;
  }

  .status {
    text-transform: capitalize;
  }
`;

export default memo(({ data, isConnectable }) => {
  const IconComp = iconGetter(data.actionType);

  return (
    <>
      <Handle type="target" position={Position.Top} />
      <Container>
        <Icon>
          <IconComp />
        </Icon>
        <Text>
          <div className="title">{data.label}</div>
          <div className="status">{data.condition}</div>
        </Text>
      </Container>
      <Handle
        type="source"
        position={Position.Bottom}
        id="yes"
        style={{ left: '25%', background: '#555' }}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="no"
        style={{ right: '25%', left: 'unset', background: '#555' }}
        isConnectable={isConnectable}
      />
    </>
  );
});
