// eslint-disable-next-line import/prefer-default-export
export const PERMISSIONS = {
  admin: {
    self: [],
    sections: {
      emails: ['create', 'read', 'update', 'delete'],
      forms: ['create', 'read', 'update', 'delete'],
      funnel: ['create', 'update', 'delete'],
      gdpr: ['read', 'update', 'delete'],
      locations: ['create', 'read', 'update', 'delete'],
      profile: ['read', 'update'],
      schedule: ['create', 'read', 'update', 'delete'],
      workflow: ['create', 'read', 'update', 'delete'],
      usermanagement: ['create', 'read', 'update', 'delete'],
      edoc: ['create', 'read', 'update', 'delete'],
      vacancytemplates: ['create', 'read', 'update', 'delete'],
      rejectionreasons: ['update'],
      branding: ['allow'],
      accessgrp: ['allow'],
      rota: ['allow'],
      atr: ['create', 'read', 'update', 'delete'],
    },
  },
  dashboard: {
    self: ['view'],
    sections: {},
  },
  candidate: {
    self: ['create', 'view', 'update', 'delete'],
    sections: {
      attachments: ['create', 'read', 'delete'],
      interview: ['create'],
      interviewnotes: ['create', 'read', 'update', 'delete'],
      notes: ['create', 'read'],
      onboarding: ['create', 'read', 'update', 'delete'],
      edoc: ['create', 'read', 'update', 'delete'],
      genericforms: ['create', 'read', 'update', 'delete'],
      timeline: ['view'],
      diversity: ['read', 'update'],
      funnel: ['update'],
      tags: ['allow'],
      references: ['create', 'view', 'update', 'delete'],
    },
  },
  communication: {
    self: [],
    sections: {
      candidate: ['create', 'read', 'update', 'delete'],
      sms: ['allow'],
    },
  },
  // cvsearch: {
  //   self: ['write', 'read', 'preview', 'view'],
  //   sections: {},
  // },
  integrations: {
    self: [],
    sections: {
      accessgrp: ['read', 'write'],
      fourth: ['admin', 'read', 'write'],
      polaris: ['admin', 'read', 'write'],
      rota: ['read', 'write'],
      s4: ['admin', 'read', 'write'],
      samesystem: ['admin', 'read', 'write'],
      'sap-nl': ['admin', 'read', 'write', 'delete'],
      ubeya: ['admin', 'read', 'write'],
      peoplehr: ['admin', 'write'],
      tamigo: ['admin', 'write'],
    },
  },
  publish: {
    self: ['create', 'read', 'update', 'delete', 'write'],
    sections: {},
  },
  reporting: {
    self: ['read', 'preview'],
    sections: {},
  },
  rtw: {
    self: ['create', 'read', 'update', 'delete'],
    sections: {},
  },
  pt: {
    title: 'test',
    self: ['create', 'read', 'update', 'delete'],
    sections: {},
  },
  pth: {
    self: ['create', 'read', 'update', 'delete'],
    sections: {},
  },
  scheduler: {
    self: [],
    sections: {
      calendar: ['create', 'read', 'update', 'delete'],
    },
  },
  talenthub: {
    self: ['plus', 'create', 'read', 'update', 'delete'],
    sections: {},
  },
  talentmatch: {
    self: ['create', 'read', 'preview'],
    sections: {},
  },
  vacancy: {
    self: ['create', 'read', 'update'],
    sections: {
      create: ['readonly'],
    },
  },
};
