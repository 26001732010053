/* eslint-disable no-nested-ternary */
import React, { useRef, useEffect, useState, Fragment, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Row, Col, Form } from 'reactstrap';
import queryString from 'query-string';
import { addFourthUser, addFourthData, addGlobalConfig } from '../../../../js/actions/fourthActions';
import { retryableAPICall } from '../../../../api/common-api-utils';
import {
  getLinkedAccount,
  getFieldData,
  submitToFourth,
  checkFourthSubmission,
  getGlobalConfig,
  getFourthSubmissionData,
} from '../../../../api/Integrations/FourthAPI';
import Tippy from '@tippyjs/react';

import { WorkflowContext } from '../../../Base/contexts/WorflowContext';
import { useFormKeypress, useMounted } from '../../../Base/hooks';
import IntegrationInput from './IntegrationInput';
import { CancelButton, CreateButton, IconButton } from '../../../Base/Buttons';
import fourthInputConfig from './fourthInputConfig';
import validation, { mapErrors } from '../../../../js/utils/validation';
import { getData as getFourthData } from '../../../Admin/Client/Fourth/FourthData';
import { mergeArrayOfObjects } from '../../../../js/utils/arrayOfObjects';

async function checkFourthStatus(onSuccess = () => {}, candidateId) {
  let resp;

  if (candidateId) {
    resp = await retryableAPICall(() => checkFourthSubmission(candidateId));

    let formData = await retryableAPICall(() => getFourthSubmissionData(candidateId));

    if (typeof formData !== 'string' && Object.keys(formData).length) {
      formData = Object.entries(formData).reduce((acc, [key, val]) => {
        let updatedVal = val;
        let updateKey = key;

        // translate to usable options
        if (key === 'location') {
          updateKey = 'locations';
        }
        if (key === 'division') {
          updateKey = 'divisions';
        }
        if (key === 'jobTitle') {
          updateKey = 'jobTitles';
        } else if (key === 'inclInRota') {
          updatedVal = /^y|1$/i.test(val) ? 'Yes' : /^n|0$/i.test(val) ? 'No' : '';
        } else if (key === 'paidByRota') {
          updatedVal = /^y|1$/i.test(val) ? 'Yes' : /^n|2$/i.test(val) ? 'No' : '';
        } else if (key === 'payType') {
          updatedVal = /^s|1$/i.test(val) ? 'Shift' : /^h|2$/i.test(val) ? 'Hourly' : '';
        }

        return {
          ...acc,
          [updateKey]: updatedVal,
        };
      }, {});

      // tidy up based on paidByRota values
      if (/^n|2$/i.test(formData.paidByRota)) {
        delete formData.rateOfPay;
        delete formData.payType;
      } else if (/^y|1$/i.test(formData.paidByRota)) {
        delete formData.annualSalary;
      }

      resp = { ...resp, formData };
    }
  } else {
    resp = await retryableAPICall(() => getLinkedAccount());
  }

  if (typeof resp === 'string' && resp.length) {
    if (resp !== 'NOT_FOUND_ERROR') {
      toast.error('Error fetching Fourth information. Please try again later or contact support');
    }
  } else {
    onSuccess(resp);
  }
}

async function fetchGlobalConfig(onSuccess = () => {}) {
  const resp = await retryableAPICall(() => getGlobalConfig());

  if (typeof resp === 'string') {
    if (resp !== 'NOT_FOUND_ERROR') {
      toast.error('Error fetching Fourth Config. Please try again later or contact support');
    }
  } else {
    onSuccess(resp);
  }
}

async function fieldData(fieldName, queryObj = {}, onComplete = () => {}, displayError = true) {
  const qs = queryString.stringify(queryObj);
  const resp = await retryableAPICall(() => getFieldData(fieldName, qs));
  let data = [];

  if (typeof resp === 'string') {
    if (displayError) toast.error(`Error fetching ${fieldName} data`);
  } else if (resp && Array.isArray(resp)) {
    data = [...resp];
  }

  onComplete(data);
}

function mapConfigToOptions(configArr = []) {
  return configArr.reduce((acc, conf) => {
    if (typeof conf === 'string' && !/^Both$/i.test(conf)) {
      return [...acc, { value: conf, label: conf }];
    }

    if (conf.label) {
      return [
        ...acc,
        {
          // id comes from global config, value from already set values
          value: conf.id || conf.value,
          label: conf.label,
        },
      ];
    }

    return acc;
  }, []);
}

const initFormData = fourthInputConfig.reduce((acc, { id }) => ({ ...acc, [id]: '' }), {});

function isCreateRestricted(res, data) {
  const buttonRestrictions = res?.find(({ component }) => component === 'fourth_submit_button') || {};

  if (!buttonRestrictions?.restrictions?.length) return false;

  const isRestricted = buttonRestrictions.restrictions.some(({ component, enableState }) => {
    if (component === 'RTW') {
      const statesToCheck = ['REJECTED', 'CONTINOUS', 'TIME_LIMITED'];
      const shouldCheckOutcomeStatus = statesToCheck.some((state) => enableState.includes(state));

      if (shouldCheckOutcomeStatus) {
        return data.rightToWork.data.outcomeStatus
          ? enableState.some((state) => state === data.rightToWork.data.outcomeStatus)
          : true;
      }

      return data.rightToWork.status ? !enableState.some((state) => state === data.rightToWork.status) : true;
    }
  });

  return isRestricted ? buttonRestrictions.restrictionMsg : false;
}

function FourthForm({
  applicantId,
  connected,
  setFourthUser,
  data,
  addDataObject,
  globalConfig,
  setGlobalConfig,
  globalConfigLoaded,
  candidateProfileSummary,
}) {
  const isMounted = useMounted();
  const { data: worflowData, isLoading } = useContext(WorkflowContext);
  const formRef = useFormKeypress();
  const [isAuthorised, setIsAuthorised] = useState(false);
  const [formData, setFormData] = useState(initFormData);
  const [isSaving, setIsSaving] = useState(false);
  const [formKey, setFormKey] = useState('');
  const [optionData, setOptionData] = useState({});
  const [errors, setErrors] = useState({});
  const [submissionStatus, setSubmissionStatus] = useState('INCOMPLETE');
  const [failReason, setFailReason] = useState();
  const validationConfig = useRef([]);

  const checkStatus = useCallback(() => {
    // check submission status
    checkFourthStatus((resp) => {
      const { statusReason, status, formData: fData } = resp;

      if (status === 'FAILED') {
        setSubmissionStatus('FAILED');
        setFailReason(statusReason);
      } else if (status === 'SUCCESS') {
        setSubmissionStatus('COMPLETE');
        setFailReason();
      }

      if (fData) {
        setFormData(fData);

        if (fData.locations !== '' && (!globalConfig.locations || !globalConfig.locations.length)) {
          fieldData('division', { location: fData.locations }, (optsResp) => {
            setOptionData((prevState) => ({ ...prevState, divisions: [...optsResp] }));
          });
        }

        if (fData.divisions !== '' && (!globalConfig.divisions || !globalConfig.divisions.length)) {
          fieldData(
            'jobTitle',
            {
              location: fData.locations,
              division: fData.divisions,
            },
            (optsResp) => {
              setOptionData((prevState) => ({ ...prevState, jobTitles: [...optsResp] }));
            },
          );
        }
      }

      // console.log(resp, JSON.stringify(resp, null, '\t'));
    }, applicantId);
  }, [applicantId, globalConfig]);

  useEffect(() => {
    if (connected) {
      setIsAuthorised(true);

      if (isMounted()) {
        if (Object.keys(data).length && data.locations) {
          fieldData('location', {}, (resp) => setOptionData({ locations: resp }), false);
        }
        if (!globalConfigLoaded) fetchGlobalConfig((resp) => setGlobalConfig(resp));

        // create validation config array
        validationConfig.current = fourthInputConfig.reduce((acc, { id, type, required }) => {
          // if select from config but not in config don't include for validation
          if (type === 'select-config' && !globalConfig[id]) return acc;
          // return a config object with validation rules
          return [...acc, { id, required, ...(type === 'number' ? { type, min: 1 } : {}) }];
        }, []);
      }
    }
  }, [connected, data, globalConfig, globalConfigLoaded, isMounted, setGlobalConfig]);

  useEffect(() => {
    if (isMounted() && !connected) {
      checkFourthStatus((resp) => {
        const { name: username, organisationId } = resp;
        setFourthUser(username, organisationId);
      });
    }
  }, [connected, isMounted, setFourthUser]);

  useEffect(() => {
    if (isMounted() && connected && !Object.keys(data).length) {
      getFourthData((respData) => addDataObject(respData));
    }
  }, [addDataObject, connected, data, isMounted]);

  useEffect(() => {
    if (connected && applicantId && isMounted()) {
      checkStatus();
    }
  }, [applicantId, checkStatus, connected, isMounted]);

  function reset(ignoreFormData) {
    if (!ignoreFormData) setFormData({ ...initFormData });
    setErrors({});
    setOptionData({ locations: optionData.locations || [] });
    setFormKey(Math.random().toString(10));
  }

  function handleChange(id, value, opts = {}) {
    const { uriFieldName, queryFieldNames, updateFieldId, queryId } = opts;

    let updatedData = { ...formData, [id]: value };
    let optData = { ...optionData };

    // only reset form when location changes if division
    // values are not on config therefore are in csv
    if (id === 'locations') {
      updatedData = { ...initFormData, locations: value };
      optData = { locations: optionData.locations || [] };
      reset(true);
    } else if (id === 'divisions') {
      updatedData = {
        ...initFormData,
        locations: updatedData.locations,
        divisions: value,
      };
      optData = {
        locations: optionData.locations || [],
        divisions: optionData.divisions || [],
      };
      reset(true);
    } else if (id === 'jobTitles') {
      updatedData = {
        ...initFormData,
        locations: updatedData.locations,
        divisions: updatedData.divisions,
        jobTitles: value,
      };
      optData = {
        locations: optionData.locations || [],
        divisions: optionData.divisions || [],
        jobTitles: optionData.jobTitles || [],
      };
      reset(true);
    }
    // when paidByRota changed
    else if (id === 'paidByRota') {
      // if no or 2
      if (/^n|2$/i.test(value)) {
        // remove rateOfPay and payType from data
        delete updatedData.rateOfPay;
        delete updatedData.payType;

        updatedData = {
          ...updatedData,
          // make sure annualSalary is added even if empty
          annualSalary: updatedData.annualSalary || '',
          // employmentType must be FullTime
          employmentType: 'FullTime',
        };
      }
      // if yes or 1
      else if (/^y|1$/i.test(value)) {
        // remove annualSalary
        delete updatedData.annualSalary;

        updatedData = {
          ...updatedData,
          // make sure rateOfPay and payType are added even if empty
          rateOfPay: updatedData.rateOfPay || '',
          payType: updatedData.payType || '',
          // employmentType must be Flex
          employmentType: 'Flex',
        };
      }
      // if reset with no value
      else {
        updatedData = {
          ...updatedData,
          // make sure all field values are added even if empty
          annualSalary: updatedData.annualSalary || '',
          rateOfPay: updatedData.rateOfPay || '',
          payType: updatedData.payType || '',
          employmentType: updatedData.employmentType || '',
        };
      }
    }
    // payType
    else if (id === 'payType') {
      // if h{ourly} | 2
      if (/^h|2$/i.test(value)) {
        updatedData = {
          ...updatedData,
          // set inclInRota as Yes by default
          inclInRota: 'Yes',
        };
      }
    }
    // inclInRota
    else if (id === 'inclInRota') {
      // if y{es} | 1
      if (/^y|1$/i.test(value)) {
        updatedData = {
          ...updatedData,
          // set inclInRota as Yes by default
          inclInRota: 'Yes',
        };
      }
      // if n{o} | 0
      else if (/^n|0$/i.test(value)) {
        updatedData = {
          ...updatedData,
          // set inclInRota as Yes by default
          inclInRota: 'No',
        };
      }
    }

    setFormData(updatedData);

    if (uriFieldName && updateFieldId && queryId && value.length) {
      let queryFields = {};

      if (queryFieldNames && Array.isArray(queryFieldNames) && queryFieldNames.length) {
        queryFields = queryFieldNames.reduce((acc, qfName) => {
          let dataId = qfName;

          if (/^location|division|jobTitle$/.test(qfName)) {
            dataId = `${qfName}s`;
          }

          return {
            ...acc,
            ...(updatedData[dataId] ? { [qfName]: updatedData[dataId] } : {}),
          };
        }, {});
      }

      fieldData(
        uriFieldName,
        { ...queryFields, [queryId]: value },
        (resp) => {
          const optsArr = [...resp];

          if (optsArr.length === 1) {
            if (id === 'payType') {
              const inclInRotaVal = optsArr[0] === 'Both' ? '' : optsArr[0];
              setFormData((prevState) => ({ ...prevState, inclInRota: inclInRotaVal }));
            } else if (id === 'jobTitles') {
              // when jobTitle doesn't give required result try a failover to get inclInRota data
              if (uriFieldName === 'paidByRota' && optsArr[0] === 'Both') {
                handleChange(id, value, {
                  ...opts,
                  uriFieldName: 'inclInRota',
                  updateFieldId: 'inclInRota',
                });

                handleChange(id, value, {
                  ...opts,
                  uriFieldName: 'payType',
                  updateFieldId: 'payType',
                });
              } else if (uriFieldName === 'inclInRota' || uriFieldName === 'payType') {
                const propVal = optsArr[0] === 'Both' ? '' : optsArr[0];
                setFormData((prevState) => ({
                  ...prevState,
                  ...(uriFieldName === 'inclInRota' ? { inclInRota: propVal } : {}),
                  ...(uriFieldName === 'payType' ? { payType: propVal } : {}),
                }));
              }
            }
          }

          setOptionData({
            // reset optionData
            ...optData,
            [updateFieldId]: optsArr,
          });
        },
        false,
      );
    }
  }

  async function handleSave() {
    setIsSaving(true);

    const errObj = validation(validationConfig.current, formData);
    const { messages, hasErrors } = mapErrors(errObj);
    setErrors(messages);

    if (!hasErrors) {
      const { locations, divisions, jobTitles, ...rest } = formData;

      const serverData = {
        ...rest,
        candidateId: applicantId,
        location: locations,
        division: divisions,
        jobTitle: jobTitles,
      };

      const resp = await submitToFourth(serverData);

      if (typeof resp === 'string') {
        toast.error('Error submitting form, please try again or contact support');
      } else {
        toast.success('Form submitted successfully');
        setSubmissionStatus('PENDING');
      }
    }

    setIsSaving(false);
  }

  if (!isAuthorised) return null;

  const isComplete = submissionStatus === 'COMPLETE';
  const isFailed = submissionStatus === 'FAILED';
  const isPending = submissionStatus === 'PENDING';
  const hasFormData = Object.values(formData).some((val) => !!val?.length);
  const isCreateButtonRestricted = isSaving || isLoading || isCreateRestricted(worflowData, candidateProfileSummary);

  return (
    <Fragment>
      <hr />
      <Row>
        <Col>
          <div className="d-flex align-items-center mb-2">
            <h4 className="mb-0 me-auto">{`Fourth - ${isComplete ? 'Completed' : isFailed ? 'Failed' : 'Pending'}`}</h4>
            {isPending && hasFormData && (
              <IconButton
                label="Refresh"
                iconClassName="fa fa-refresh"
                floatRight={false}
                size="sm"
                action={() => checkStatus()}
              />
            )}
          </div>
          {failReason && (
            <div className="alert alert-danger">
              <p>{failReason}</p>
            </div>
          )}
          <Form innerRef={formRef} key={formKey}>
            {fourthInputConfig.map((inpConf) => {
              const {
                id,
                label,
                uriFieldName,
                queryFieldNames,
                updateFieldId,
                queryId,
                fallbackType,
                required,
                optionLookup,
                updatedBy,
              } = inpConf;
              let { type, helpText } = inpConf;

              // Do not show rateOfPay or payType if paidByRota is no or 2
              if (/^rateOfPay|payType|employmentType$/.test(id) && /^n|2$/i.test(formData.paidByRota)) {
                return null;
              }

              if (globalConfig.hiddenInputs && globalConfig.hiddenInputs.includes(id)) {
                return null;
              }

              // Do not show annualSalary if paidByRota is yes or 1
              if (id === 'annualSalary' && /^y|1$/i.test(formData.paidByRota)) {
                return null;
              }

              let options = [];

              if (!isComplete) {
                options = mapConfigToOptions(globalConfig[id]?.length ? globalConfig[id] : optionData[id] || []);

                if (type === 'select-config') {
                  type = 'select';
                  options = mapConfigToOptions(globalConfig[id] || []);

                  // if not in config
                  if (!globalConfig[id]) {
                    if (fallbackType) {
                      type = fallbackType;
                    }
                  }
                } else if (type === 'select') {
                  // if Both set as return item don't dictate value
                  if (optionData[id] && optionData[id].length === 1 && optionData[id][0] === 'Both') {
                    options = mapConfigToOptions(optionLookup);
                  }

                  if (options.length === 1) {
                    // paidByRota / payType / inclInRota when not in globalConfig and using CSV values
                    if (!formData[id] && formData[updatedBy]?.length) {
                      const { value } = options[0];
                      handleChange(id, value, {
                        uriFieldName,
                        queryFieldNames,
                        updateFieldId,
                        queryId,
                      });
                    }
                  }

                  if (options.length) helpText = '';

                  // has optionLookup to produce options but no options in globalConfig
                  if (options.length < 2 && optionLookup && (!globalConfig[id] || !globalConfig[id].length)) {
                    // create an options array from lookup values
                    const asOptArr = mapConfigToOptions(optionLookup);
                    // set as options
                    options = mergeArrayOfObjects(asOptArr, options, 'value');
                  }
                }
              }

              return (
                <IntegrationInput
                  key={id}
                  type={type}
                  label={label}
                  id={id}
                  options={options}
                  value={formData[id] || ''}
                  onChange={(val) => {
                    handleChange(id, val, {
                      uriFieldName,
                      queryFieldNames,
                      updateFieldId,
                      queryId,
                    });
                  }}
                  required={required}
                  error={errors[id]}
                  readOnly={isComplete}
                  helpText={helpText}
                />
              );
            })}
            {!isComplete && (
              <div className="float-end d-flex">
                <CancelButton
                  className="mt-2"
                  label="Clear"
                  isLoading={isSaving}
                  disabled={isSaving}
                  action={() => reset()}
                />
                <Tippy
                  // eslint-disable-next-line max-len
                  content={isCreateButtonRestricted ? isCreateButtonRestricted || 'Cannot submit at this time' : null}
                  theme="ats"
                  disabled={!isCreateButtonRestricted}
                >
                  <div>
                    <CreateButton
                      className="mt-2"
                      label={isSaving ? 'Submitting data...' : 'Submit Candidate to Fourth'}
                      isLoading={isSaving || isLoading}
                      disabled={isCreateButtonRestricted}
                      action={(e) => {
                        e.preventDefault();
                        handleSave();
                      }}
                    />
                  </div>
                </Tippy>
              </div>
            )}
          </Form>
        </Col>
      </Row>
    </Fragment>
  );
}

FourthForm.propTypes = {
  applicantId: PropTypes.string,
  connected: PropTypes.bool,
  setFourthUser: PropTypes.func,
  data: PropTypes.shape(),
  addDataObject: PropTypes.func,
  globalConfig: PropTypes.shape(),
  setGlobalConfig: PropTypes.func,
  globalConfigLoaded: PropTypes.bool,
  candidateProfileSummary: PropTypes.shape(),
};

FourthForm.defaultProps = {
  applicantId: null,
  connected: false,
  setFourthUser: () => {},
  data: {},
  addDataObject: () => {},
  globalConfig: {},
  setGlobalConfig: () => {},
  globalConfigLoaded: false,
  candidateProfileSummary: null,
};

function mapStateToProps(state) {
  const {
    fourth: { connected, data, globalConfig, globalConfigLoaded },
  } = state;

  return {
    connected,
    data,
    globalConfig,
    globalConfigLoaded,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setFourthUser: (username, organisationId) => {
      dispatch(addFourthUser(username, organisationId));
    },
    addDataObject: (dataObj) => {
      dispatch(addFourthData(dataObj));
    },
    setGlobalConfig: (configObj) => {
      dispatch(addGlobalConfig(configObj));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FourthForm);
