import React from 'react';
import PropTypes from 'prop-types';
import FunnelSelectInputs from '../../../../../../Base/Forms/Custom/FunnelSelect/FunnelSelectInputs';
import EmailTemplateSelect from './EmailTemplateSelect';

function Action({ option, setOption }) {
  if (
    option?.actionType === 'REQUEST_ONBOARDING' ||
    option?.actionType === 'SEND_USER_EMAIL' ||
    option?.actionType === 'SEND_CANDIDATE_CONTACT_EMAIL' ||
    option?.actionType === 'REQUEST_RIGHT_TO_WORK_DOCS' ||
    option?.actionType === 'SPECIFIC_EDOCUMENTS_REQUEST' ||
    option?.actionType === 'SPECIFIC_SEND_FLEXIFORM_REQUEST' ||
    option?.actionType === 'REQUEST_DBS' ||
    option?.actionType === 'REQUEST_PERSONALITY_TEST'
  ) {
    return (
      <>
        <EmailTemplateSelect
          actionType={option?.actionType}
          id={`emailTemplateConfig-`}
          onChange={(emailTemplate) => setOption({ emailTemplate })}
          required
          value={option?.emailTemplate || ''}
        />
      </>
    );
  }

  if (
    option?.actionType === 'SEND_SMS' ||
    option?.actionType === 'REQUEST_REFEREES' ||
    option?.actionType === 'SEND_TO_UBEYA' ||
    option?.actionType === 'SEND_TO_FLOW' ||
    option?.actionType === 'REQUEST_REFERENCES' ||
    option?.actionType === 'REQUEST_REFERENCES' ||
    option?.actionType === 'SEND_TO_SMART'
  ) {
    return <div />;
  }

  if (
    option?.actionType === 'ADD_REMOVE_TAGS' ||
    option?.actionType === 'SEND_REMINDERS' ||
    option?.actionType === 'ADD_TO_TALENT_POOL'
  ) {
    return <div>Undecied what is neeeded</div>;
  }

  if (option?.actionType === 'UPDATE_FUNNEL_STAGE') {
    return (
      <FunnelSelectInputs
        funnelSelectProps={{
          id: `actionFunnelId`,
          label: 'Funnel',
          required: true,
          value: option?.funnelId || '',
        }}
        onChange={(val) => {
          setOption({ funnelId: val.funnelId, stageId: val.stageId });
        }}
        stageSelectProps={{
          id: `actionStageId`,
          label: 'Stage',
          required: true,
          value: option?.stageId || '',
        }}
      />
    );
  }

  if (option?.actionType === 'REQUEST_SPECIFIC_EDOC') {
    return (
      <div>
        <EmailTemplateSelect
          actionType={option?.actionType}
          id={`emailTemplateConfig-`}
          onChange={(val) => handleChange('emailTemplateConfig', { id: val })}
          required
          value={''}
        />
        <FormsSelector
          label={data.type === 'REQUEST_EDOC' ? 'Select Document' : 'Select Form'}
          onChange={(vals, values) => {
            const docIds = {};

            const mappedAllValues = values.map((val) => {
              return { id: val.value, label: val.label, value: val.value };
            });

            mappedAllValues.forEach((val) => {
              docIds[val.id] = val.label;
            });
            //handleChange('docIds', docIds);
          }}
          selectedValues={[]}
          type={'EDOC'}
        />

        <p style={{ color: 'rgba(5,39,119,0.73)' }}>
          <IconSVG name="Info Circle" />
          <span style={{ marginLeft: '5px' }}>
            Only documents with standard placeholders can be automated. If any documents contain custom placeholders the
            workflow will not be executed.
          </span>
        </p>
      </div>
    );
  }

  return <div>No Condition</div>;
}

Action.propTypes = {
  option: PropTypes.shape(),
  setOption: PropTypes.func,
};

Action.defaultProps = {
  option: {},
  setOption: () => {},
};

export default Action;
