export const schema = {
  type: 'object',
  required: [
    'status',
    'firstName',
    'surname',
    'email',
    // 'password',
    // 'passwordConfirm',
    // 'accounts',
    // 'defaultAccount',
  ],
  properties: {
    status: {
      type: 'string',
      title: 'User Status',
      data: [
        { label: 'Active', value: 'ACTIVE' },
        { label: 'Pending', value: 'PENDING' },
      ],
    },
    firstName: {
      type: 'string',
      title: 'First Name',
    },
    surname: {
      type: 'string',
      title: 'Last Name',
    },
    // phoneNumber: {
    //   type: 'string',
    //   title: 'Phone Number',
    //   maxLength: 15,
    //   minLength: 8,
    // },
    email: {
      type: 'string',
      title: 'Email Address',
      format: 'email',
    },
    password: {
      type: 'string',
      title: 'Password',
    },
    passwordConfirm: {
      type: 'string',
      title: 'Confirm Password',
    },
    accounts: {
      type: 'array',
      title: 'Account(s) to have access to',
    },
    defaultAccount: {
      type: 'array',
      title: 'Default account',
    },
    restrictedUser: {
      type: 'boolean',
      title: 'Hiring Manager/Restricted User',
    },
    // tenantId: {
    //   type: 'string',
    //   title: 'Tenant',
    // },
    // groups: {
    //   type: 'array',
    //   title: 'Groups',
    // },
    // restrictedUser: {
    //   type: 'boolean',
    //   title: 'Restricted User / Hiring Manager',
    //   default: false,
    // },
    permissions: {
      type: 'array',
      title: 'Permissions',
    },
  },
};

export const uiSchema = {
  'ui:order': Object.keys(schema.properties),
  'ui:rootFieldId': 'userAdmin',
  status: {
    'ui:field': 'singleSelect',
  },
  password: {
    'ui:widget': 'password',
  },
  passwordConfirm: {
    'ui:widget': 'password',
  },
  accounts: {
    'ui:field': 'accountSelect',
    'ui:options': {
      ignoreActiveAccount: false,
    },
  },
  defaultAccount: {
    'ui:field': 'accountSelect',
    'ui:options': {
      isMulti: false,
      ignoreActiveAccount: false,
    },
    className: 'default-account',
  },
  permissions: {
    'ui:field': 'permissionsMatrix',
  },
  restrictedUser: {
    classNames: 'default-cb mb-0 mt-3 res-user',
    'ui:help': 'This user will only be able to see and act on vacancies and their candidates directly assigned to them',
  },
};
