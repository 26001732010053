// Contains a set of API calls to middleware services in order to get data around events
import Poller from 'api-poll';
import axios from 'axios';
import config from '../../config/config';
import { getCommonHeaders, mapErrorResponse } from '../common-api-utils';

const { api, analytics } = config;

export async function searchAnalytics(search) {
  const result = await axios.post (
    `${api.middlewareAPIURL}${analytics.searchAnalyticsPath}`,
    search,
    { headers: getCommonHeaders() },
  );

  if (result.status === 200) return result.data;

  return null;
}
