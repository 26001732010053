// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Document, Page, pdfjs } from 'react-pdf';
import { toast } from 'react-toastify';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

function PDFDisplay({ file, isBase64, onFileDownload }) {
  const [pageCount, setPageCount] = useState(1);
  const [scale, setScale] = useState(1.2);

  const fileStr = isBase64 ? `data:application/pdf;base64,${file}` : file;

  useEffect(() => {
    const resize = () => {
      const page = document.querySelector('.react-pdf__Document');

      const newPageScale = page.clientWidth / 600;

      if (scale !== newPageScale) {
        setScale(newPageScale || 1);
      }
    };
    window.addEventListener('resize', resize);

    resize();
    return () => {
      window.removeEventListener('resize', resize);
    };
  }, [scale]);

  return (
    <Document
      file={fileStr}
      onLoadError={(error) => {
        if (onFileDownload) {
          onFileDownload();
        } else {
          toast.error(`Error loading document ${error.message}`);
        }
      }}
      onLoadSuccess={({ numPages }) => setPageCount(numPages)}
      onSourceError={(error) => toast.error(`Error retrieving source ${error.message}`)}
    >
      {Array.from(new Array(pageCount), (el, index) => (
        <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={scale} />
      ))}
    </Document>
  );
}

PDFDisplay.propTypes = {
  file: PropTypes.string,
  isBase64: PropTypes.bool,
  onFileDownload: PropTypes.func,
};

PDFDisplay.defaultProps = {
  file: null,
  isBase64: false,
  onFileDownload: null,
};

export default PDFDisplay;
