/* eslint-disable no-nested-ternary */
import React, { useState, useContext } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';
import { toast } from 'react-toastify';
import { retryableAPICall } from '../../../../api/common-api-utils';
import { CreateButton } from '../../../Base/Buttons';
import { submitToSAP } from '../../../../api/Integrations/SAPAPI';
import { WorkflowContext } from '../../../Base/contexts/WorflowContext';

function isCreateRestricted(res, candidateProfileSummary, componentName) {
  const buttonRestrictions = res?.find(({ component }) => component === componentName) || {};

  if (!buttonRestrictions?.restrictions?.length) return false;

  const isRestricted = buttonRestrictions.restrictions.some(({ component, enableState }) => {
    if (component === 'RTW') {
      return candidateProfileSummary.rightToWork.status
        ? enableState.some((state) => state === candidateProfileSummary.rightToWork.status)
        : true;
    }

    if (component === 'ONBOARDING') {
      return candidateProfileSummary.onboarding.status
        ? enableState.some((state) => state === candidateProfileSummary.onboarding.status)
        : true;
    }

    if (component === 'EDOC') {
      return candidateProfileSummary.edoc.length > 0
        ? candidateProfileSummary.edoc.every((edoc) => {
            return enableState.some((state) => state === edoc.status);
          })
        : false;
    }
  });

  return isRestricted ? buttonRestrictions.restrictionMsg : false;
}

function SAPIntegration({ candidate, candidateProfileSummary }) {
  const [sapStatus, setSapStatus] = useState('NOT_SUBMITTED');
  const { data: worflowData, isLoading } = useContext(WorkflowContext);

  async function handleSubmit() {
    setSapStatus('SUBMITTING');
    const result = await retryableAPICall(() => submitToSAP(candidate.id));

    if (typeof result === 'string') {
      toast.error('Could not submit to SAP');
    } else {
      setSapStatus('SUBMITTED');
      toast.success('Candidate successfully submitted to SAP');
    }
  }

  const isCreateButtonRestricted =
    sapStatus !== 'NOT_SUBMITTED' ||
    isLoading ||
    isCreateRestricted(worflowData, candidateProfileSummary, 'ubeya_submit_button');

  return (
    <>
      <hr />
      <Row>
        <Col>
          <Tippy content={isCreateButtonRestricted} disabled={!isCreateButtonRestricted}>
            <div className="float-end">
              <CreateButton action={handleSubmit} disabled={isCreateButtonRestricted} label="Send to SAP" />
            </div>
          </Tippy>
        </Col>
      </Row>
    </>
  );
}

SAPIntegration.propTypes = {
  candidate: PropTypes.shape(),
  candidateProfileSummary: PropTypes.shape(),
};

SAPIntegration.defaultProps = {
  candidate: null,
  candidateProfileSummary: null,
};

export default connect(null, null)(SAPIntegration);
