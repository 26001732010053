import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import cx from 'classnames';
import { useMounted } from '../../../hooks';
import { retryableAPICall } from '../../../../../api/common-api-utils';
import { searchVacancy } from '../../../../../api/SearchAPI/SearchAPI';
import { SelectInput } from '../CommonComponents';
import { getFormattedAddress } from '../AddressLookup';

export async function getVacancies(activeAccountId, activePendingOnly) {
  const filters = [];

  if (activeAccountId) {
    filters.push({
      field: 'accountId',
      operation: 'IN',
      value: [activeAccountId],
    });
  }

  if (activePendingOnly) {
    filters.push({
      field: 'status',
      operation: 'IN',
      value: ['ACTIVE', 'PENDING', 'AUTHORISATION_REJECTED', 'AWAITING_AUTHORISATION'],
    });
  }

  const resp = await retryableAPICall(() =>
    searchVacancy(
      undefined,
      undefined,
      filters,
      ['id', 'positionProfile.title', 'positionProfile.location', 'accountName', 'company'],
      undefined,
      1000,
      undefined,
    ),
  );

  if (typeof resp === 'string') {
    toast.error('Error loading Vacancies, please try again later');
    return [];
  }

  return resp.results.map(({ id, positionProfile, company }) => {
    const { title, location } = positionProfile;
    const { name: companyName = '' } = company;
    const formattedAddr = getFormattedAddress(location);

    const metaData = `${formattedAddr.length ? formattedAddr : ''}${companyName.length ? ` - ${company.name}` : ''}`;

    return {
      value: id,
      label: `${title}${metaData.length ? ` (${metaData})` : ''}`,
    };
  });
}

function VacancySelect({
  id,
  className,
  value,
  onChange,
  placeholder,
  disabled,
  isMulti,
  isClearable,
  currentVacancyId,
  ignoreCurrentVacancy,
  activeAccountId,
  activePendingOnly,
}) {
  const isMounted = useMounted();
  const [vacSelectOpts, setVacSelectOpts] = useState([]);

  const getOptions = useCallback(async () => {
    let opts = await getVacancies(activeAccountId, activePendingOnly);

    if (ignoreCurrentVacancy && currentVacancyId) {
      opts = opts.filter(({ value: val }) => val !== currentVacancyId);
    }

    if (isMounted()) setVacSelectOpts(opts);
  }, [activeAccountId, currentVacancyId, ignoreCurrentVacancy, isMounted]);

  useEffect(() => {
    getOptions();
  }, [getOptions]);

  return (
    <SelectInput
      id={id}
      className={cx('vacancy-select', className)}
      placeholder={placeholder}
      disabled={disabled}
      isMulti={isMulti}
      isClearable={isClearable}
      options={vacSelectOpts}
      value={value}
      onChange={onChange}
    />
  );
}

VacancySelect.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  isClearable: PropTypes.bool,
  currentVacancyId: PropTypes.string,
  ignoreCurrentVacancy: PropTypes.bool,
  activeAccountId: PropTypes.string,
  activePendingOnly: PropTypes.bool,
};

VacancySelect.defaultProps = {
  id: null,
  className: null,
  value: '',
  onChange: () => {},
  placeholder: 'Select...',
  disabled: false,
  isMulti: false,
  isClearable: false,
  currentVacancyId: null,
  ignoreCurrentVacancy: false,
  activeAccountId: null,
  activePendingOnly: false,
};

export default VacancySelect;
