import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { v1 as uuidv1 } from 'uuid';
import { addActiveFilter } from '../../../../js/actions/filterActions';
import { checkPermissions } from '../../../../js/auth/AuthUtils';

const CheckboxFilter = ({ activeFilters, filterName, filterData, removeFilter, addFilter }) => {
  const initialFilters = activeFilters[filterName] || [];

  const statusFilter = initialFilters.find((filter) => filter.type === 'Checkbox' && filter.field === 'status');

  const isATRFilterActive = statusFilter?.value.every((a) =>
    ['AUTHORISATION_REJECTED', 'AWAITING_AUTHORISATION'].includes(a),
  );
  const isClosedFilterActive = statusFilter?.value.includes('CLOSED');

  const handleFilterChange = (filterType, isChecked) => {
    let filterValues = ['ACTIVE', 'PENDING', 'AUTHORISATION_REJECTED', 'AWAITING_AUTHORISATION'];
    removeFilter(statusFilter?.instanceId);

    if (
      (isClosedFilterActive && filterType == 'CLOSED' && isChecked) ||
      (!isClosedFilterActive && filterType == 'CLOSED' && isChecked)
    ) {
      filterValues.push('CLOSED');
    }

    if (
      (isATRFilterActive && filterType == 'ATR' && isChecked) ||
      (!isATRFilterActive && filterType == 'ATR' && isChecked)
    ) {
      filterValues = ['AUTHORISATION_REJECTED', 'AWAITING_AUTHORISATION'];
    }

    const newInstanceId = uuidv1();

    const filterConf = {
      id: '0',
      field: 'status',
      operation: 'EQ',
      value: filterValues,
      instanceId: newInstanceId,
      filterName,
      isNew: true,
      type: 'Checkbox',
    };

    addFilter(filterConf);
  };

  return (
    <>
      {checkPermissions(['admin:atr:read']) ? (
        <>
          <label htmlFor="auth-filter-cb" className="default-filter mb-2">
            <input
              id="auth-filter-cb"
              type="checkbox"
              className="standard-checkbox"
              checked={isATRFilterActive}
              onChange={(event) => handleFilterChange('ATR', event.currentTarget.checked)}
            />
            <span>{'Display Awaiting Authorisation'}</span>
          </label>
        </>
      ) : null}
      <label htmlFor="closed-filter-cb" className="default-filter mb-2">
        <input
          id="closed-filter-cb"
          type="checkbox"
          className="standard-checkbox"
          checked={isClosedFilterActive}
          onChange={(event) => handleFilterChange('CLOSED', event.currentTarget.checked)}
        />
        <span>{'Include closed/expired'}</span>
      </label>
    </>
  );
};

CheckboxFilter.propTypes = {
  activeFilters: PropTypes.objectOf(PropTypes.array).isRequired,
  filterName: PropTypes.string.isRequired,
  filterData: PropTypes.arrayOf(PropTypes.object).isRequired,
  removeFilter: PropTypes.func.isRequired,
  addFilter: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  addFilter: (filter) => dispatch(addActiveFilter(filter)),
});

export default connect(null, mapDispatchToProps)(CheckboxFilter);
