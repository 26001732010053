export const delayTypes = [
  { value: 0, label: 'Immediately' },
  { value: 60, label: '1 minute' },
  { value: 600, label: '10 minutes' },
  { value: 1800, label: '30 minutes' },
  { value: 3600, label: '1 hour' },
  { value: 14400, label: '4 hours' },
  { value: 28800, label: '8 hours' },
  { value: 43200, label: '12 hours' },
  { value: 86400, label: '1 day' },
  { value: 259200, label: '3 days' },
  { value: 604800, label: '7 days' },
];

// eslint-disable-next-line import/no-mutable-exports
const trigTypes = [
  { label: 'New Candidate', value: 'CREATE_CANDIDATE_EVENT' },
  { label: 'Event Status Change', value: 'EVENT_STATUS_CHANGE' },
  { label: 'Funnel Status Change', value: 'FUNNEL_STATE_CHANGE' },
];

// if (checkPermissions(['rtw:read'])) {
trigTypes.push({ value: 'RTW_STATUS_CHANGE', label: 'Right To Work Status Change' });
// }

// if (checkPermissions(['candidate:references:view'])) {
trigTypes.push(
  { value: 'REFEREE_STATUS_CHANGE', label: 'Referee Status Change' },
  { value: 'REFERENCE_STATUS_CHANGE', label: 'Reference Status Change' },
);
// }
// if (checkPermissions(['candidate:onboarding:read'])) {
trigTypes.push({ value: 'ONBOARDING_STATUS_CHANGE', label: 'Onboarding Status Change' });
// }
// console.log(checkPermissions(['admin:edoc:read']))
// if (checkPermissions(['admin:edoc:read'])) {
trigTypes.push({ value: 'EDOC_STATUS_CHANGE', label: 'eDocument Status Change' });

trigTypes.push({ value: 'GENERIC_FORM_STATUS_CHANGE', label: 'FlexiForm Status Change' });
trigTypes.push({ value: 'PERSONALITY_TEST_STATUS_CHANGE', label: 'Personality Test Status Change' });
// }
trigTypes.push({ value: 'PAYROLL_INTEGRATION_SUBMISSION', label: 'Payroll submission' });

export const triggerTypes = trigTypes.sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));

export const rtwResultTypes = [
  { value: '', label: 'Any' },
  { value: 'REJECTED', label: 'Rejected' },
  { value: 'TIME_LIMITED', label: 'Time Limited' },
  { value: 'CONTINUOUS', label: 'Continuous' },
];

export const eventStates = [
  { value: 'INVITED', label: 'Invited' },
  { value: 'ACCEPTED', label: 'Accepted' },
  { value: 'ATTENDED', label: 'Attended' },
  { value: 'DECLINED', label: 'Declined' },
  { value: 'NO_SHOW', label: 'No Show' },
];

export const onboardingStates = [
  { value: 'REQUESTED', label: 'Requested' },
  { value: 'RECEIVED', label: 'Received' },
];

export const referenceStates = [
  { value: 'REQUESTED', label: 'Requested' },
  { value: 'PART_RECEIVED', label: 'Partly received' },
  { value: 'RECEIVED', label: 'Received' },
];

export const ptTestStates = [
  { value: 'REQUESTED', label: 'Requested' },
  { value: 'PART_RECEIVED', label: 'Received' }, // this service returns part_received when the scores are in
];

export const payrollStatusStates = [
  { value: 'SUCCESS', label: 'Successfully submitted' },
  { value: 'FAILED', label: 'Failed to submit' }, // this service returns part_received when the scores are in
];

export const candidateDataFilter = [
  { label: 'All', value: 'ALL' },
  { label: 'UTM Source', value: 'utmSource' },
  { label: 'UTM Campaign', value: 'utmCampaign' },
  { label: 'UTM Medium', value: 'utmMedium' },
];

export const emailActions = [{ value: 'SEND_CONTACT_TEMPLATE_EMAIL', label: 'Send Candidate Contact Email' }];

export const onboardingActions = [
  { value: 'UPDATE_FUNNEL_STAGE', label: 'Update Funnel Stage' },
  { value: 'REQUEST_REFEREES', label: 'Request Referees' },
  ...emailActions,
];

export const flexiFormActions = [
  { value: 'UPDATE_FUNNEL_STAGE', label: 'Update Funnel Stage' },
  { value: 'REQUEST_ONBOARDING', label: 'Request Onboarding' },
  { value: 'REQUEST_REFEREES', label: 'Request Referees' },
  { value: 'REQUEST_RTW', label: 'Request Right To Work Docs' }, // Will be removed if user doesn't have permission
  { value: 'REQUEST_EDOC', label: 'Request eDocuments' }, // Will be removed if user doesn't have permission
  ...emailActions,
];

export const refereeActions = [
  { value: 'REQUEST_REFERENCES', label: 'Request References' },
  ...emailActions,
  { value: 'UPDATE_FUNNEL_STAGE', label: 'Update Funnel Stage' },
];
export const referenceActions = [...emailActions, { value: 'UPDATE_FUNNEL_STAGE', label: 'Update Funnel Stage' }];

export const eDocActions = [...emailActions, { value: 'UPDATE_FUNNEL_STAGE', label: 'Update Funnel Stage' }];

export const rtwActions = [...emailActions, { value: 'UPDATE_FUNNEL_STAGE', label: 'Update Funnel Stage' }];

export const personalityTestActions = [...emailActions, { value: 'UPDATE_FUNNEL_STAGE', label: 'Update Funnel Stage' }];

export const payrollSubmissionActions = [...emailActions, { value: 'UPDATE_FUNNEL_STAGE', label: 'Update Funnel Stage' }];

export const funnelActions = [
  { value: 'REQUEST_ONBOARDING', label: 'Request Onboarding' },
  { value: 'REQUEST_REFEREES', label: 'Request Referees' },
  { value: 'SEND_TO_UBEYA', label: 'Send to Ubeya' }, // Will be removed if user doesn't have permission
  { value: 'SEND_TO_FLOW', label: 'Send to Flow' }, // Will be removed if user doesn't have permission
  { value: 'REMOVE_FROM_FLOW', label: 'Remove From Flow' }, // Will be removed if user doesn't have permission
  { value: 'REQUEST_GENERIC_FORM', label: 'Send FlexiForm Request' }, // Will be removed if user doesn't have permission
  { value: 'SEND_TO_SMART', label: 'Send to Smart' }, // Will be removed if user doesn't have permission
  { value: 'SEND_TO_SAP', label: 'Send to SAP' }, // Will be removed if user doesn't have permission
  { value: 'REQUEST_RTW', label: 'Request Right To Work Docs' }, // Will be removed if user doesn't have permission
  { value: 'REQUEST_EDOC', label: 'Request eDocuments' }, // Will be removed if user doesn't have permission
  { value: 'REQUEST_PERSONALITY_TEST', label: 'Request Personality Test' }, // Will be removed if user doesn't have permission
  ...emailActions,
];

export const eventActions = [
  ...onboardingActions.filter(({ value }) => value !== 'SEND_CONTACT_TEMPLATE_EMAIL'),
  ...funnelActions.filter(({ value }) => value !== 'SEND_CONTACT_TEMPLATE_EMAIL'),
  ...emailActions,
];
