import React, {
  Fragment, useState, useCallback, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  FormGroup,
  Input,
  Label,
  Col,
  FormFeedback,
  Button,
} from 'reactstrap';
import cx from 'classnames';
import Select from 'react-select';
import ErrorFeedback from '../../Base/ErrorFeedback/ErrorFeedback';
import { EmailPillsInput, TagPill } from '../../Base/Forms/Custom/EmailPills';

const inputPropTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  options: PropTypes.array,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onChange: PropTypes.func,
  isReadOnly: PropTypes.bool,
};

const inputDefaultProps = {
  type: 'text',
  required: false,
  value: '',
  onChange: () => { },
  isReadOnly: false,
};

function AddressDisplay({ value, onClick, isExpanded }) {
  const isArr = Array.isArray(value);
  if (!isArr || (isArr && (!value.length || value.length === 1))) return null;

  return (
    <Button
      size="sm"
      color="link"
      onClick={onClick}
    >
      {isExpanded ? 'Hide' : `View all ${value.length} recipients`}
    </Button>
  );
}

AddressDisplay.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.func,
  isExpanded: PropTypes.bool,
};

AddressDisplay.defaultProps = {
  value: [],
  onClick: () => { },
  isExpanded: false,
};

const DEFAULT_HEIGHT = 140;

function TextInput({
  id,
  type,
  required,
  disabled,
  value,
  onChange,
  invalid,
  errorMessage,
  isReadOnly,
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showExpandBtn, setShowExpandBtn] = useState(false);

  const divInpRef = useCallback((node) => {
    if (node !== null) {
      const { height } = node.getBoundingClientRect();
      if (height > DEFAULT_HEIGHT) setShowExpandBtn(true);
    }
  }, []);

  if (type === 'div' && Array.isArray(value)) {
    return (
      <Fragment>
        <div className="form-control" style={{ maxHeight: isExpanded ? 'none' : `${DEFAULT_HEIGHT}px` }}>
          <div ref={divInpRef} className="tag-wrapper">
            {value.map((v, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <TagPill key={`${v}-${i}`} index={i} showRemove={false}>{v}</TagPill>
            ))}
          </div>
        </div>
        {showExpandBtn && (
          <AddressDisplay value={value} isExpanded={isExpanded} onClick={() => setIsExpanded(!isExpanded)} />
        )}
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Input
        type={type}
        id={id}
        value={value}
        disabled={disabled}
        plaintext={disabled}
        onChange={(e) => onChange(e.target.value)}
        required={required}
        invalid={invalid}
        readOnly={isReadOnly}
      />
      <FormFeedback>{errorMessage}</FormFeedback>
    </Fragment>
  );
}

TextInput.propTypes = {
  ...inputPropTypes,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  errorMessage: PropTypes.string,
};

TextInput.defaultProps = {
  ...inputDefaultProps,
  disabled: false,
  invalid: false,
  errorMessage: null,
};

export default function InlineFormInp(props) {
  const {
    id,
    label,
    type,
    required,
    value,
    errorMessage,
    isToggled,
    isHidden,
    isMulti,
    invalid,
    options,
    onChange,
  } = props;

  const [selectedOpt, setSelectedOpt] = useState(undefined);

  useEffect(() => {
    if (type === 'select' && options && value) {
      setSelectedOpt(options.find((itm) => itm.value === value));
    }
  }, [options, type, value]);

  return (
    <FormGroup
      row
      className={cx({
        'is-toggle': isToggled,
        'is-hidden': isHidden,
      })}
      data-input-id={id}
    >
      {label && (
        // eslint-disable-next-line jsx-a11y/label-has-for
        <Label
          for={id}
          sm="3"
          className={required ? 'required-input-label' : ''}
        >
          {`${label}:`}
        </Label>
      )}
      <Col sm="9">
        {isMulti && (type === 'email') ? (
          <EmailPillsInput
            value={value}
            onChange={(emails) => onChange(emails)}
            errorMessage={errorMessage}
          />
        ) : (
          <Fragment>
            {type === 'select' ? (
              <Fragment>
                <Select
                  onChange={(opt) => onChange(opt.value)}
                  options={options || []}
                  value={selectedOpt}
                  classNamePrefix={cx('react-select', { 'is-invalid': invalid })}
                />
                <ErrorFeedback message={errorMessage} />
              </Fragment>
            ) : <TextInput {...props} />}
          </Fragment>
        )}
      </Col>
    </FormGroup>
  );
}

InlineFormInp.propTypes = {
  ...inputPropTypes,
  label: PropTypes.string,
  isToggled: PropTypes.bool,
  isHidden: PropTypes.bool,
  isMulti: PropTypes.bool,
};

InlineFormInp.defaultProps = {
  ...inputDefaultProps,
  label: null,
  isToggled: false,
  isHidden: false,
  isMulti: false,
};
