import React, { useCallback, useRef, useState } from 'react';
import ReactFlow, { Controls, Background, useNodesState, useEdgesState, updateEdge, addEdge } from 'reactflow';
import styled from 'styled-components';
import EditNodeSlider from './Sliders/EditNodeSlider/EditNodeSlider';
import './workflow.css';
import 'reactflow/dist/style.css';
import AnnotationNode from './CustomNodes/AnnotationNode';
import DecisionNode from './CustomNodes/DecisionNode';
import EndNode from './CustomNodes/EndNode';
import StartNode from './CustomNodes/StartNode';
import ActionNode from './CustomNodes/ActionNode';
import NodeSelector from './NodeSelector';
import YesEdge from './CustomEdges/YesEdge';
import NoEdge from './CustomEdges/NoEdge';
import WaitNode from './CustomNodes/WaitNode';

import { toast } from 'react-toastify';

const nodeTypes = {
  annotationNode: AnnotationNode,
  decisionNode: DecisionNode,
  waitNode: WaitNode,
  endNode: EndNode,
  startNode: StartNode,
  actionNode: ActionNode,
};

const edgeTypes = {
  yesEdge: YesEdge,
  noEdge: NoEdge,
};

const Container = styled.div`
  flex-grow: 1;
  min-height: 60vh;
  max-height: 70vh;
`;

const getNextId = (nodes) => {
  if (nodes.length === 0) return '1';
  return String(Math.max(...nodes.map((node) => parseInt(node.id, 10))) + 1);
};

const WorkflowWrapper = ({ initialEdges, initialNodes, updateNodes }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const [highlightNode, setHighlightNode] = useState(null);
  const reactFlowInstance = useRef(null);
  const edgeUpdateSuccessful = useRef(true);

  const onConnect = useCallback(
    (params) => {
      // First, check if the source node is a decision node.
      const sourceNode = nodes.find((node) => node.id === params.source);
      const isDecisionNode = sourceNode && sourceNode.type === 'decisionNode';

      // Count the existing edges from this source.
      const existingEdgesFromSource = edges.filter((edge) => edge.source === params.source).length;

      // Allow connection only if it's a decision node or if there are no existing edges from this source.
      if (isDecisionNode || existingEdgesFromSource === 0) {
        let newEdge = {
          id: `e${params.source}-${params.target}`,
          source: params.source,
          target: params.target,
        };

        if (isDecisionNode) {
          newEdge = {
            ...newEdge,
            label: params.sourceHandle === 'yes' ? 'Yes' : 'No',
            sourceHandle: params.sourceHandle,
            type: params.sourceHandle === 'yes' ? 'yesEdge' : 'noEdge',
          };
        }

        setEdges((eds) => addEdge(newEdge, eds));
      } else {
        toast.error('Only decision nodes can have multiple outgoing edges.');
      }
    },
    [nodes, edges, setEdges],
  );

  const onNodeClick = (e, node) => {
    setSelectedNode(node);
    setIsModalOpen(true);
  };

  const onEdgeUpdateStart = useCallback(() => {
    edgeUpdateSuccessful.current = false;
  }, []);

  const onEdgeUpdate = useCallback(
    (oldEdge, newConnection) => {
      edgeUpdateSuccessful.current = true;
      setEdges((els) => updateEdge(oldEdge, newConnection, els));
    },
    [setEdges],
  );

  const onEdgeUpdateEnd = useCallback(
    (_, edge) => {
      if (!edgeUpdateSuccessful.current) {
        setEdges((eds) => eds.filter((e) => e.id !== edge.id));
      }
      edgeUpdateSuccessful.current = true;
    },
    [setEdges],
  );

  const onDragOver = useCallback(
    (event) => {
      event.preventDefault();
      event.dataTransfer.dropEffect = 'move';

      const reactFlowBounds = reactFlowInstance.current.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });

      const proximityNode = findClosestNode(reactFlowBounds);

      if (proximityNode) {
        setHighlightNode(proximityNode);
      } else {
        setHighlightNode(null);
      }
    },
    [setHighlightNode, nodes],
  );

  const findClosestNode = (position) => {
    let closestNode = null;

    nodes.forEach((node) => {
      const nodeBounds = {
        x: node.position.x,
        y: node.position.y,
        width: node.width,
        height: node.height,
      };

      if (
        position.x >= nodeBounds.x &&
        position.x <= nodeBounds.x + nodeBounds.width &&
        position.y >= nodeBounds.y &&
        position.y <= nodeBounds.y + nodeBounds.height
      ) {
        closestNode = node;
      }
    });

    return closestNode;
  };

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      if (reactFlowInstance.current) {
        const type = event.dataTransfer.getData('application/reactflow');
        const label = event.dataTransfer.getData('label');
        const actionType = event.dataTransfer.getData('actionType');

        const position = reactFlowInstance.current.screenToFlowPosition({
          x: event.clientX,
          y: event.clientY,
        });

        const newNode = {
          id: getNextId(nodes),
          type,
          position,
          data: { label, actionType: actionType },
        };

        setNodes((nds) => nds.concat(newNode));

        if (highlightNode && highlightNode?.type !== 'endNode') {
          const sourceNode = nodes.find((node) => node.id === highlightNode.id);
          const isDecisionNode = sourceNode.type === 'decisionNode';
          const existingEdgesFromSource = edges.filter((edge) => edge.source === highlightNode.id).length;

          if (isDecisionNode || existingEdgesFromSource === 0) {
            const newEdge = {
              id: `e${highlightNode?.id}-${newNode.id}`,
              source: highlightNode?.id,
              target: newNode.id,
            };
            setEdges((eds) => eds.concat(newEdge));
          }
        }
        setHighlightNode(null);
      }
    },
    [setNodes, setEdges, nodes, highlightNode],
  );

  const getNodeStyle = (nodeId) => ({
    background: nodeId === highlightNode?.id ? '#ffcc00' : undefined,
    border: nodeId === highlightNode?.id ? '2px solid yellow' : 'none',
  });

  return (
    <Container className="dndflow">
      <NodeSelector nodes={nodes} />
      <ReactFlow
        nodes={nodes.map((node) => ({
          ...node,
          style: { ...node.style, ...getNodeStyle(node.id) },
        }))}
        nodeTypes={nodeTypes}
        edges={edges}
        edgeTypes={edgeTypes}
        onNodesChange={(a) => {
          onNodesChange(a);
          updateNodes(a, edges);
        }}
        onEdgeUpdate={onEdgeUpdate}
        onEdgeUpdateStart={onEdgeUpdateStart}
        onEdgeUpdateEnd={onEdgeUpdateEnd}
        onEdgesChange={(a) => {
          onEdgesChange(a);
          updateNodes(nodes, a);
        }}
        onConnect={onConnect}
        onInit={(instance) => (reactFlowInstance.current = instance)}
        onNodeClick={onNodeClick}
        fitView
        onDragOver={onDragOver}
        onDrop={onDrop}
        attributionPosition="top-right"
      >
        <Background />
        <Controls />
      </ReactFlow>
      <EditNodeSlider
        isSliderOpen={isModalOpen}
        closeSlider={(e) => {
          setIsModalOpen(false);
        }}
        onSave={(value) => {
          const updatedNodes = nodes.map((node) => {
            if (node.id === selectedNode.id) {
              return { ...node, data: { ...node.data, ...value } };
            }

            return node;
          });

          setNodes(updatedNodes);
          updateNodes(updatedNodes);
          setIsModalOpen(false);
        }}
        selectedNode={selectedNode}
        selectedEdges={edges.filter((edge) => edge.source === selectedNode?.id)}
        onDelete={() => {
          if (selectedNode.type === 'startNode') {
            // Input node wipes it all
            setEdges([]);
            setNodes([]);
            updateNodes([], []);
            setIsModalOpen(false);
          } else {
            const updatedEdges = edges.filter(
              (edge) => !(edge.source === selectedNode.id || edge.target === selectedNode.id),
            );
            setEdges(updatedEdges);
            setNodes(nodes.filter((node) => node.id !== selectedNode.id));

            updateNodes(
              nodes.filter((node) => node.id !== selectedNode.id),
              updatedEdges,
            );
            setIsModalOpen(false);
          }
        }}
      />
    </Container>
  );
};

export default WorkflowWrapper;
