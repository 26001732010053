import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Label, Input, FormGroup } from 'reactstrap';
import moment from 'moment';
import SelectOption, { optionDataProp } from './SelectOption';
import { DatePickerInput } from '../../Forms/Custom/DatePicker';

export const selectedValueProp = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.string),
  PropTypes.string,
  PropTypes.number,
  PropTypes.instanceOf(moment),
  PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  PropTypes.shape({
    question: PropTypes.string,
    answer: PropTypes.string,
  }),
]);

export default function FilterOption(props) {
  const { type, id, condition, label, checkedVal, onRadioChange, onSelect, isMulti, optionData, openCalDown } = props;
  let { selectedValue } = props;

  if (!id || !condition) return null;

  const isChecked = checkedVal === condition;

  // ensure selected value for select is a string if multiple is false
  if (type !== 'Date' && !isMulti && Array.isArray(selectedValue)) {
    selectedValue = selectedValue.length ? selectedValue[0] : '';
  }

  const filterId = `filter-${id}`;

  return (
    <>
      <FormGroup check className="filter-information-option">
        {/* eslint-disable-next-line jsx-a11y/label-has-for */}
        <Label check for={filterId}>
          <Input
            className="standard-checkbox"
            defaultChecked={isChecked}
            id={filterId}
            name="filter"
            onChange={(e) => {
              const {
                target: { value },
              } = e;
              onRadioChange(value);
            }}
            type="radio"
            value={condition}
          />
          <span>{label}</span>
        </Label>
      </FormGroup>
      {isChecked && (
        <div className="filter-input-wrapper mt-2 mb-4">
          {type === 'Date' ? (
            <DatePickerInput
              id={id}
              onChange={onSelect}
              openDirection={openCalDown ? 'down' : 'up'}
              pastDates
              value={selectedValue}
            />
          ) : (
            <Input
              multiple={isMulti}
              onChange={(e) => {
                const { target } = e;
                const selectedValues = [...target.options].filter((opt) => opt.selected).map((opt) => opt.value);
                onSelect(selectedValues);
              }}
              type="select"
              value={selectedValue || (isMulti ? ['DEFAULT'] : 'DEFAULT')}
            >
              <>
                {!isMulti && (
                  <SelectOption disabled value="DEFAULT">
                    Choose...
                  </SelectOption>
                )}
                {optionData.map((opt) => {
                  let optValue = opt;
                  let optLabel = opt;

                  if (typeof opt === 'object') {
                    optValue = opt.value;
                    optLabel = opt.label;
                  }

                  return (
                    <SelectOption key={optValue} value={optValue}>
                      {optLabel}
                    </SelectOption>
                  );
                })}
              </>
            </Input>
          )}
        </div>
      )}
    </>
  );
}

FilterOption.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string,
  condition: PropTypes.string,
  label: PropTypes.string,
  checkedVal: PropTypes.string,
  selectedValue: selectedValueProp,
  onRadioChange: PropTypes.func,
  onSelect: PropTypes.func,
  isMulti: PropTypes.bool,
  optionData: optionDataProp,
  openCalDown: PropTypes.bool,
};

FilterOption.defaultProps = {
  id: null,
  condition: null,
  label: null,
  checkedVal: null,
  selectedValue: null,
  onRadioChange: () => {},
  onSelect: () => {},
  isMulti: false,
  optionData: [],
  openCalDown: true,
};
