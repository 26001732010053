/* eslint-disable jsx-a11y/label-has-for */
import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { CreateButton, CancelButton } from '../../../Base/Buttons';
import { uploadUtils } from '../../../Base/Forms/Custom/FileUpload';
import { retryableAPICall } from '../../../../api/common-api-utils';
import { getCsvFieldData, uploadCsvFieldData } from '../../../../api/Integrations/FourthAPI';
import { IntegrationInput } from '../Dashboard';
import { addFourthData } from '../../../../js/actions/fourthActions';
import FourthGlobalConfig from './FourthGlobalConfig';
import { useMounted } from '../../../Base/hooks';

const { processFiles } = uploadUtils;

export async function getData(onSuccess = () => {}) {
  const resp = await retryableAPICall(() => getCsvFieldData());

  if (typeof resp === 'string') {
    if (resp !== 'NOT_FOUND_ERROR') {
      toast.error('Error fetching data. Please try again later');
    }
  } else {
    onSuccess(resp);
  }
}

function FourthData({ data, connected, addDataObject }) {
  const isMounted = useMounted();
  const [isSaving, setIsSaving] = useState(false);
  const [csvFile, setCsvFile] = useState();
  const [csvData, setCsvData] = useState('');
  const [error, setError] = useState();
  const [inpKey, setInpKey] = useState('');

  useEffect(() => {
    if (Object.keys(data).length && data.locations) {
      setCsvData(JSON.stringify(data.locations));
    }
  }, [data]);

  useEffect(() => {
    if (isMounted() && connected && !Object.keys(data).length) {
      getData((respData) => addDataObject(respData));
    }
  }, [addDataObject, connected, data, isMounted]);

  function handleChange(e) {
    const {
      target: { files },
    } = e;

    processFiles(files, {
      // 15mb
      maxSize: 15000000,
    }).then((processedFiles) => {
      const invalid = processedFiles.every((file) => typeof file === 'string');

      if (invalid) {
        setError('File too large. Maximum file size is 15mb');
      } else {
        setCsvFile(processedFiles[0]);
      }
    });
  }

  function resetFileInput() {
    setInpKey(Math.random().toString(10));
    setCsvFile();
  }

  async function handleUpload() {
    setIsSaving(true);

    const resp = await retryableAPICall(() => uploadCsvFieldData(csvFile));

    if (typeof resp === 'string' && resp.length) {
      toast.error('Error uploading file. Please try again later');
    } else {
      toast.success('File uploaded successfully');
      getData((respData) => addDataObject(respData));
      resetFileInput();
    }

    setIsSaving(false);
  }

  return (
    <>
      <FormGroup>
        <Label for="csvfields">
          Current Data
          {' - '}
          <small>(Read only)</small>
        </Label>
        <Input
          defaultValue={csvData}
          id="csvfields"
          readOnly
          rows="5"
          style={{ minHeight: '200px', backgroundColor: '#fff' }}
          type="textarea"
        />
      </FormGroup>
      <IntegrationInput
        className="mt-3"
        colClassName="d-flex align-items-center"
        error={error}
        id="csvfileupload"
        inputProps={{
          accept: 'text/csv',
          key: inpKey,
        }}
        label="Upload data"
        onChange={handleChange}
        type="file"
      />
      {csvFile && (
        <>
          <CreateButton
            action={(e) => {
              e.preventDefault();
              handleUpload();
            }}
            className="mt-2"
            disabled={isSaving}
            floatRight={false}
            isLoading={isSaving}
            label={isSaving ? 'Uploading...' : 'Upload new CSV file'}
          />
          <CancelButton action={() => resetFileInput()} className="mt-2 ms-2" floatRight={false} />
        </>
      )}
      <FourthGlobalConfig />
    </>
  );
}

FourthData.propTypes = {
  data: PropTypes.shape(),
  connected: PropTypes.bool,
  addDataObject: PropTypes.func,
};

FourthData.defaultProps = {
  data: {},
  connected: false,
  addDataObject: () => {},
};

function mapStateToProps(state) {
  const {
    fourth: { data, connected },
  } = state;
  return { data, connected };
}

function mapDispatchToProps(dispatch) {
  return {
    addDataObject: (dataObj) => {
      dispatch(addFourthData(dataObj));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FourthData);
