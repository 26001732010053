import React from 'react';
import PropTypes from 'prop-types';
import {
  FormFeedback,
} from 'reactstrap';
import classNames from 'classnames';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import TagPill from './TagPill';

function EmailPillsInput({ value, onChange , errorMessage}) {
  return (<>
    <ReactMultiEmail
      emails={value}
      getLabel={(email, index, removeEmail) => (
        <TagPill key={index} index={index} removeTag={removeEmail}>
          {email}
        </TagPill>
      )}
      onChange={(emails) => onChange(emails)}
      validateEmail={(email) => isEmail(email)}
      className={classNames({ ['is-invalid']: errorMessage })}
    />
    <FormFeedback>{errorMessage}</FormFeedback>
    </>
  );
}

EmailPillsInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  onChange: PropTypes.func,
  errorMessage: PropTypes.string
};

EmailPillsInput.defaultProps = {
  value: [],
  onChange: () => {},
  errorMessage: null
};

export default EmailPillsInput;
